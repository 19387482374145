import React from "react";
import AppButton from "../../components/Button/Button";
import JadeLogo from "../../assets/logo.png";
import { Form, message } from "antd";
import CommonTextInput from "../../components/Input/Input";
import { useState } from "react";
import {
  forgotPasswordVerifyAPI,
  verifyOTPAPI,
} from "../../apis/auth/AuthAPIs";
import { useLocation, useNavigate } from "react-router-dom";
import { constRoute, CREDENTIALS } from "../../utils/Constants";
import { useDispatch } from "react-redux";
import { _setLoginAction } from "../../redux/actions/auth/AuthActions";
import { useTranslation } from "react-i18next";
// comment
const VerifyOTP = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const phoneNo =
    props.type === "forgot-password" ? props.phoneNo : location.state?.phoneNo;
  const phoneToken = location.state?.phoneToken;
  const [messageApi, contextHolder] = message.useMessage();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const verifyOTPSignup = async (values) => {
    try {
      setLoading(true);
      const result = await verifyOTPAPI({
        phoneNo: "+" + phoneNo,
        phoneToken,
        otp: Number(values.otp),
      });
      setLoading(false);
      if (result.data.statusCode === 200 && result.data.data) {
        localStorage.setItem(
          CREDENTIALS.AUTH_TOKEN,
          JSON.stringify(result.data.data.authToken)
        );
        dispatch(_setLoginAction(true, result.data.data));
        navigate("/");
      } else throw new Error(result.data.errors.phoneNo);
    } catch (e) {
      console.log("error >>>>", e);
      setLoading(false);
      messageApi.error(
        e.response?.data?.errors?.otp ??
          "An unknown error occured, please try again with the correct otp"
      );
    }
  };

  const verifyOTPForgotPassword = async (values) => {
    try {
      setLoading(true);
      const result = await forgotPasswordVerifyAPI({
        otp: Number(values.otp),
        phoneNo: phoneNo,
      });
      setLoading(false);
      if (result.data.statusCode === 200 && result.data.data) {
        navigate(constRoute.forgotPassword, {
          state: {
            phoneNo: phoneNo,
            phoneToken: result.data.data.token,
          },
        });
      } else throw new Error(result.data.errors.phoneNo);
    } catch (e) {
      console.log("error >>>>", e);
      setLoading(false);
      messageApi.error(
        e.response?.data?.errors?.otp ??
          "An unknown error occured, please try again with the correct otp"
      );
    }
  };
  return (
    <div className="login-main">
      {contextHolder}
      <img width="40" src={JadeLogo} alt={"logo"} />
      <div className="welcome-text">
        <h3 className="bold__font">{t(`forgotPassword.OTPVerification`)}</h3>
        <p>{t(`forgotPassword.enterCode`)}</p>
      </div>
      <Form
        form={form}
        onFinish={
          props.type === "forgot-password"
            ? verifyOTPForgotPassword
            : verifyOTPSignup
        }
        className={"login-form"}
      >
        <Form.Item
          name={"otp"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <CommonTextInput
            placeholder={t(`forgotPassword.OTP`)}
            className="input"
          />
        </Form.Item>
        <AppButton
          type="primary"
          className={"sign-in-btn"}
          htmlType={"submit"}
          loading={loading}
        >
          {t(`forgotPassword.submit`)}
        </AppButton>
      </Form>
    </div>
  );
};

export default VerifyOTP;
