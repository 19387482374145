import { applyMiddleware, combineReducers, createStore } from "redux";
import persistReducer from "redux-persist/es/persistReducer";

import storage from "redux-persist/lib/storage";
import persistStore from "redux-persist/es/persistStore";
import thunk from "redux-thunk";
// import profileReducer from "../reducers/profile/profileReducer";
import AuthReducer from "../reducers/auth/AuthReducer";
import HomeReducer from "../reducers/home/HomeReducer";
import AuctionsReducer from "../reducers/auctions/AuctionsReducer";
import WatchlistReducer from "../reducers/watchlist/WatchlistReducer";
import BidsReducer from "../reducers/bids/BidsReducer";

const rootReducer = combineReducers({
  auth: AuthReducer,
  home: HomeReducer,
  auctions: AuctionsReducer,
  bids: BidsReducer,
  watchlist: WatchlistReducer,
//   userData: profileReducer,
});
const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
// @todo : use configureStore method by redux toolkit
export const Store = createStore(persistedReducer, applyMiddleware(thunk));
export const persistor = persistStore(Store);
