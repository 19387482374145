import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import { Col, Row } from "antd";
import LeftArrow from "../../assets/left-arrow.svg";
import useWindowSize from "../../utils/hooks/useWindowSize";
import { useNavigate } from "react-router-dom";
import PhoneIcon from "../../assets/phone-call.svg";
import FaxIcon from "../../assets/fax.svg";
import WeChatIco from "../../assets/wechat-icon.svg";
import AddressIcon from "../../assets/address.svg";
import ProtectedHeader from "../../components/ProtectedHeader/ProtectedHeader";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader/Loader";
import { getCompanyAPI } from "../../apis/company";
import {
  renderItemDataOrEmptyNull,
  requestErrorHandel,
} from "../../utils/common-functions";

const ContactUS = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [loading, setLoading] = useState(false);
  const [companyData, setCompanyData] = useState(null);

  const getCompany = async () => {
    setLoading(true);
    try {
      const response = await getCompanyAPI();
      if (response?.data?.statusCode === 200) {
        setCompanyData(response?.data?.data);
      }
    } catch (error) {
      requestErrorHandel(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCompany();
  }, []);

  return (
    <>
      <div className={style.headerContainer}>
        <ProtectedHeader />
      </div>
      <Loader loading={loading} />
      <div className={style.pageContainer}>
        <div className={style.fluidContainer}>
          <Row justify={width <= 768 ? "center" : "left"}>
            {width <= 768 && (
              <img
                onClick={() => navigate("/")}
                className={style.arrowIcon}
                src={LeftArrow}
                width={20}
                alt=""
              />
            )}
            <h1>{t(`contactUs.contactUs`)}</h1>
          </Row>

          <Row className={style.fluidRow} gutter={40}>
            <Col md={12} sm={24} xs={24}>
              <a
                target="_blank"
                rel="noreferrer"
                disabled={
                  companyData?.phoneNo?.value === null ||
                  companyData?.phoneNo?.value === ""
                }
                href={`tel:+${companyData?.phoneNo?.value}`}
              >
                <Row className={style.contactCardContainer}>
                  <img src={PhoneIcon} alt="" />
                  <div>
                    <p>{t(`contactUs.telephone`)}</p>
                    <p>
                      {renderItemDataOrEmptyNull(companyData?.phoneNo?.value)}
                    </p>
                  </div>
                </Row>
              </a>
            </Col>
            <Col md={12} sm={24} xs={24}>
              <a
                target="_blank"
                rel="noreferrer"
                disabled={
                  companyData?.email?.value === null ||
                  companyData?.email?.value === ""
                }
                href={`mailto:${companyData?.email?.value}`}
              >
                <Row className={style.contactCardContainer}>
                  <img src={FaxIcon} alt="" />
                  <div>
                    <p>{t(`contactUs.email`)}</p>
                    <p>
                      {renderItemDataOrEmptyNull(companyData?.email?.value)}
                    </p>
                  </div>
                </Row>
              </a>
            </Col>
            <Col md={12} sm={24} xs={24}>
              <a
                target="_blank"
                rel="noreferrer"
                disabled={
                  companyData?.weChat?.value === null ||
                  companyData?.weChat?.value === ""
                }
                href={companyData?.weChat?.value}
              >
                <Row className={style.contactCardContainer}>
                  <img src={WeChatIco} alt="" />
                  <div>
                    <p>{t(`contactUs.weChat`)}</p>
                    <p>
                      {renderItemDataOrEmptyNull(companyData?.weChat?.value)}
                    </p>
                  </div>
                </Row>
              </a>
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Row className={style.contactCardContainer}>
                <img src={AddressIcon} alt="" />
                <div>
                  <p>{t(`contactUs.address`)}</p>
                  <p>
                    {renderItemDataOrEmptyNull(companyData?.address?.value)}
                  </p>
                </div>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ContactUS;
