export const PLACE_BID_ACTION = "PLACE_BID_ACTION";
export const SET_ALL_MY_BIDS = "SET_ALL_MY_BIDS";
export const SET_ALL_MY_SINGLE_BID = "SET_ALL_MY_SINGLE_BID";

export const _placeBidAction = (data) => {
    return dispatch => dispatch({
        type: PLACE_BID_ACTION,
        payload: data
    })
}

export const _setMyBidsAction = (data) => {
    return dispatch => dispatch({
        type: SET_ALL_MY_BIDS,
        payload: data
    })
}

export const _setMySingleBidAction = (data) => {
    console.log("data", data)
    return dispatch => dispatch({
        type: SET_ALL_MY_SINGLE_BID,
        payload: data
    })
}