import { Col, Empty, Row } from "antd";
import NotificationIcon from "../../assets/notification-icon-red.svg";
import "./NotificationList.css";
import { useEffect, useState } from "react";
import {
  formatDate,
  getTimeAMPMFromDate,
  renderItemDataOrEmptyNull,
  requestErrorHandel,
} from "../../utils/common-functions";
import { getNotificationApi } from "../../apis/notification";
import Loader from "../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

const NotificationList = ({ isLoggedIn }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [notificationList, setNotificationList] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getNotificationApi();
      if (response?.data?.statusCode === 200) {
        setNotificationList(response?.data?.data);
      }
    } catch (error) {
      requestErrorHandel(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  

  const navigateToRoute = (data) => {
    
    switch (data?.type) {
      case 'auction':
        console.log("type action")
        navigate(`/auction-detail/${data?.auctionId}`);
        break;
      default:
        break;
    }
  };

  return (
    <div className="notifications-main">
      <Loader loading={loading} />

      {notificationList.length > 0 && isLoggedIn ? (
        notificationList.map((notif) => (
          <Row gutter={[0, 10]} className="notifications-row">
            <Col md={24}>
              <Row className="main-row" align={"middle"} justify={"space-between"}>
                <Row align={"middle"}>
                  <img
                    className="notify-ocon"
                    src={NotificationIcon}
                    alt={"notifcation"}
                  />

                  {/* {renderStatus(notif.status)} */}
                  <div>
                  <p className="notif-msg medium__font">
                    <b>{renderItemDataOrEmptyNull(notif?.data?.title)}</b>
                    {`: ${renderItemDataOrEmptyNull(notif?.data?.body)}`}{" "}
                    {notif?.type?.length && (
                      <span
                        onClick={() => navigateToRoute(notif)}
                        className="type-label"
                      >
                        {" "}
                        {notif?.type}
                      </span>
                    )}
                  </p>

                  <p className="notif-date t-slate medium__font">
                    {formatDate(notif?.createdAt)}
                  </p>
                  </div>
                  
                </Row>
                <Row justify={"space-between"}>
                  <p className="notif-date t-slate medium__font">
                    {getTimeAMPMFromDate(notif?.createdAt)}
                  </p>
                </Row>
              </Row>
            </Col>
          </Row>
        ))
      ) : (
        <Empty
          description={
            isLoggedIn
              ? t("noNotifications")
              : t("youNeedToLoginToViewNotifications")
          }
        />
      )}
    </div>
  );
};

export default NotificationList;
