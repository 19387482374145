import React from "react";
import style from "./style.module.scss";
import { Col, Form, message, Row } from "antd";
import LeftArrow from "../../assets/left-arrow.svg";
import useWindowSize from "../../utils/hooks/useWindowSize";
import { useNavigate } from "react-router-dom";
import ProtectedHeader from "../../components/ProtectedHeader/ProtectedHeader";
import { setRules } from "../../utils/common-functions";
import CustomButton from "../../components/CommonComponents/custom-button/CustomButton";
import CommonTextInput from "../../components/Input/Input";
import { updatePasswordAPI } from "../../apis/auth/AuthAPIs";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const UpdatePassword = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);

  const submit = async (values) => {
    console.log({ values });
    try {
      setLoading(true);
      const data = {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      };
      console.log({ data });
      const result = await updatePasswordAPI(data);
      if (result.data.statusCode === 200) {
        messageApi.success("Your password has been updated");
        setLoading(false);
      } else return result.data;
    } catch (e) {
      console.log(e);
      setLoading(false);
      messageApi.error(
        e.response?.data?.errors?.password ??
          "An error occured while updating your password, please try again"
      );
    }
  };

  return (
    <div>
      {contextHolder}
      <div className={style.headerContainer}>
        <ProtectedHeader />
      </div>
      <div className={style.updatePasswordContainer}>
        <div className={style.pageContainer}>
          <div className={style.fluidContainer}>
            <Form
              layout="vertical"
              className={style.cancelModalForm}
              form={form}
              onFinish={submit}
            >
              <Row justify={width <= 768 ? "center" : "left"}>
                {width <= 768 && (
                  <img
                    onClick={() => navigate("/")}
                    className={style.arrowIcon}
                    src={LeftArrow}
                    width={20}
                    alt=""
                  />
                )}
                <h1>{t(`updatePassword.updatePassword`)}</h1>
              </Row>

              <Row className={style.fluidRow} gutter={40}>
                <Col md={20} sm={24} xs={24}>
                  <Form.Item
                    rules={setRules("Current password")}
                    name={"oldPassword"}
                  >
                    <CommonTextInput
                      placeholder={t("updatePassword.currentPassword")}
                      type={"password"}
                    />
                  </Form.Item>
                </Col>
                <Col md={20} sm={24} xs={24}>
                  <Form.Item
                    rules={setRules("New password")}
                    name={"newPassword"}
                  >
                    <CommonTextInput
                      placeholder={t("updatePassword.newPassword")}
                      type={"password"}
                    />
                  </Form.Item>
                </Col>
                <Col md={20} sm={24} xs={24}>
                  <Form.Item
                    rules={[
                      ...setRules("Repeat password"),
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value === form.getFieldValue("newPassword")) {
                            return Promise.resolve();
                          } else
                            return Promise.reject(
                              new Error("Password does not match")
                            );
                        },
                      }),
                    ]}
                    name={"repeatPassword"}
                  >
                    <CommonTextInput
                      placeholder={t("updatePassword.repeatPassword")}
                      type={"password"}
                    />
                  </Form.Item>
                </Col>
                <Col className={style.btnContainer} span={24}>
                  <CustomButton
                    htmlType="submit"
                    className={style.btn}
                    title={t("updatePassword.update")}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatePassword;
