import client from "../config"

// login
export const loginAPI = async ({email, password}) => {
    return await client.post(`/auth/login`, {email, password})
}

// signup
export const signupAPI = async (data) => {
    return await client.post(`/register`, data )
}

export const sendOTPAPI = async (phoneNo) => {
    return await client.post(`/auth/password/forgot`, phoneNo)
}

export const verifyOTPAPI = async ({otp, phoneNo, phoneToken}) => {
    return await client.post(`/auth/phone/otp/verify`, {
        otp, phoneNo, phoneToken
    })
}

// forgot password
export const forgotPasswordPhoneAPI = async (phoneNo) => {
    return await client.post(`/auth/password/forgot`, {phoneNo} )
}
export const forgotPasswordVerifyAPI = async ({otp, phoneNo}) => {
    return await client.post(`/auth/password/otp/verify`, {otp, phoneNo} )
}
export const forgotPasswordChangeAPI = async ({phoneNo, phoneToken, newPassword}) => {
    return await client.put(`/auth/password`, {phoneNo, phoneToken, newPassword} )
}

// change password
export const updatePasswordAPI = async (data) => {
    return await client.patch(`/users/password`, data )
}

// update profile
export const updateProfileAPI = async (data) => {
    return await client.put(`/users/profile`, data)
}

export const getUserProfile = async (data) => {
    return await client.get(`/users/profile`, data)
}