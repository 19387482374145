import { Image, Row } from "antd";
import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { getSignedURLAPI } from "../../apis/upload/UploadS3API";
import "./ImageInput.css";

const ImageInput = ({
  placeholder,
  value,
  onSelect,
  onRemove,
  setUploading,
  ...props
}) => {
  const [preview, setPreview] = useState();
  const uploadRef = useRef();
  const onUpload = () => {
    uploadRef.current.click();
  };
  const onRemoveImage = () => {
    onSelect("");
    setPreview();
  };

  const onChangeValue = async (e) => {
    const file = e.target.files[0];
    let mimeType = file.type;
    mimeType = mimeType.split("/")[1];
    setPreview(URL.createObjectURL(file));
    try {
      if (mimeType) {
        setUploading(true);
        const signedURLResult = await getSignedURLAPI(mimeType);
        if (signedURLResult.data.data) {
          const signedURL = signedURLResult.data.data;
          const requestObject = {
            method: "PUT",
            body: file,
          };
          requestObject.headers = {
            "Content-Type": mimeType,
          };
          const awsresponse = await fetch(signedURL, requestObject);
          if (awsresponse.url) {
            onSelect(awsresponse.url.split("?")[0]);
            setUploading(false);
          } else throw awsresponse;
        }
      }
    } catch (e) {
      setUploading(false);
      console.log("error in aws file upload", e);
    }
  };
  return (
    <Row
      className={`common-image-input ${props.className}`}
      align={"middle"}
      justify={"space-between"}
    >
      {preview ? <Image src={preview} /> : <span>{placeholder}</span>}
      <input
        type={"file"}
        style={{ display: "none" }}
        value={""}
        ref={uploadRef}
        onChange={onChangeValue}
      />
      {preview ? (
        <span className={"bold__font t-red t-cursor"} onClick={onRemoveImage}>
          Remove
        </span>
      ) : (
        <span className={"bold__font t-red t-cursor"} onClick={onUpload}>
          {props.buttonText}
        </span>
      )}
    </Row>
  );
};

export default ImageInput;
