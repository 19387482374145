import { useLocation } from "react-router-dom";
import VerifyOTP from "../VerifyOTP/VerifyOTP";

const ForgotPasswordVerify = () => {
  const { state } = useLocation();
  const phoneNo = state.phoneNo;
  return <VerifyOTP phoneNo={phoneNo} type={"forgot-password"} />;
};

export default ForgotPasswordVerify;
