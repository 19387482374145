import React from "react";
import HomeSearchBar from "../../components/HomeSearchBar/HomeSearchBar";
import "./Home.css";
import ProtectedHeader from "../../components/ProtectedHeader/ProtectedHeader";
import HomeCategories from "../../components/HomeCategories/HomeCategories";
import HomeAuctionList from "../../components/HomeAuctionList/HomeAuctionList";
import {
  getHomeCategoriesAPI,
  getFeaturedAuctionsAPI,
} from "../../apis/home/HomeAPIs";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  _setAuctionsAction,
  _setCategoriesAction,
} from "../../redux/actions/home/HomeActions";
import { useCallback } from "react";
import { useState } from "react";
import Loader from "../../components/Loader/Loader";
import { Row } from "antd";
import { useNavigate } from "react-router-dom";
import { constRoute } from "../../utils/Constants";
import { _setAllAuctionsAction } from "../../redux/actions/auctions/AuctionsActions";
import { useTranslation } from "react-i18next";

const Home = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const categories = useSelector((state) => state.home.categories);
  const auctions = useSelector((state) => state.home.auctions);
  const [selectedCategory, selectCategory] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");

  const [loading, setLoading] = useState(false);

  const viewAllCategories = () => navigate(constRoute.allCategories);

  const fetchCategories = useCallback(async () => {
    try {
      setLoading(true);
      const result = await getHomeCategoriesAPI({ limit: 8 });
      if (result.data?.statusCode === 200) {
        setLoading(false);
        dispatch(_setCategoriesAction(result.data.data));
      }
    } catch (e) {
      setLoading(false);
      console.log("error in fetch categories...", e);
    }
  }, [dispatch]);

  const fetchAuctions = useCallback(async () => {
    try {
      setLoading(true);
      const result = await getFeaturedAuctionsAPI(
        selectedCategory,
        searchKeyword
      );
      if (result.data?.statusCode === 200 && result.data.data) {
        setLoading(false);
        const data = selectedCategory
          ? result.data.data
          : result.data.data.filter((item) => item.isFeature);
        dispatch(_setAuctionsAction(data));
        dispatch(_setAllAuctionsAction(data));
      }
    } catch (e) {
      setLoading(false);
      console.log("error in fetch auctions...", e);
    }
  }, [dispatch, selectedCategory, searchKeyword]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  useEffect(() => {
    fetchAuctions();
  }, [fetchAuctions, selectedCategory]);

  const openAuctionsByCategory = (categoryId, title) => {
    if (categoryId === "allAuctions") {
      navigate(constRoute.auctions, {
        state: { category: { id: "", title } },
      });
    } else {
      navigate(constRoute.auctions, {
        state: { category: { id: categoryId, title } },
      });
    }
  };

  const openAuctionsBySearch = (search) => {
    navigate(constRoute.auctions, {
      state: { search },
    });
  };

  return (
    <div className="common-layout home-main">
      <Loader loading={loading} />
      <ProtectedHeader />
      <section className="header-section">
        <HomeSearchBar
          // onChange={(e) => setSearchKeyword(e.target?.value ?? "")}
          onChange={(e) => openAuctionsBySearch(e.target?.value ?? "")}
          placeholder={t(`home.searchAuctions`)}
        />
        <Row align={"middle"} justify="space-between" style={{ marginTop: 20 }}>
          <h2 className="bold__font">{t(`home.exploreCategories`)}</h2>
          <p
            onClick={viewAllCategories}
            className="t-red t-underline medium__font t-cursor"
          >
            {categories?.length > 7 && t(`home.viewAll`)}
          </p>
        </Row>
        <HomeCategories
          data={categories}
          selected={selectedCategory}
          // onSelect={selectCategory}
          onSelect={(id, title) => openAuctionsByCategory(id, title)}
        />
      </section>
      <section className="body-section">
        <HomeAuctionList data={auctions} />
      </section>
    </div>
  );
};

export default Home;
