import { SET_LOGIN, UPDATE_PROFILE } from "../../actions/auth/AuthActions";

const initialState = {
  isLoggedIn: false,
  user: undefined,
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGIN:
      return { isLoggedIn: action.payload.loggedIn, user: action.payload.user };
    case UPDATE_PROFILE:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};

export default AuthReducer;
