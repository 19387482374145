// import { Header } from "antd/es/layout/layout";
import { Row } from "antd";
import React, { useEffect, useState } from "react";
import "./Header.css";

import WhatsAppIcon from "../../assets/whatsapp-icon.svg";
import WeChatIcon from "../../assets/wechat-icon.svg";
import TikTokIcon from "../../assets/tiktok-icon.svg";
import Link from "antd/es/typography/Link";
import { useTranslation } from "react-i18next";
import { getCompanyAPI } from "../../apis/company";
import { renderItemDataOrEmptyNull, requestErrorHandel } from "../../utils/common-functions";

const AppHeader = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [companyData, setCompanyData] = useState(null);


  const getCompany = async () => {
    setLoading(true);
    try {
      const response = await getCompanyAPI();
      if (response?.data?.statusCode === 200) {
        setCompanyData(response?.data?.data);
      }
    } catch (error) {
      requestErrorHandel(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCompany()
  }, [])
  


  return (
    <header className="header">
      <Row className="first-row" align={"middle"}>
        <p className="header-text">{t(`header.followUs`)}</p>
        <Link target="_blank" href={companyData?.tikTok?.value}>
          <img src={TikTokIcon} alt={"tiktok"} />
        </Link>
        <Link target="_blank" href={companyData?.weChat?.value}>
          <img src={WeChatIcon} alt={"WeChat"} />
        </Link>
        <Link target="_blank" href={`https://wa.me/${companyData?.whatsApp?.value}`}>
          <img src={WhatsAppIcon} alt={"whatsapp"} />
        </Link>
      </Row>
      <p className="header-text">
        {t(`header.email`)}:{" "}
        <a className="header-text" href="mailto:info@mkjades.com">{renderItemDataOrEmptyNull(companyData?.email?.value)}</a>
      </p>
    </header>
  );
};

export default AppHeader;
