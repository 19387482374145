export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_AUCTIONS = "SET_AUCTIONS";

export const _setCategoriesAction = (data) => {
    return dispatch => dispatch({
        type: SET_CATEGORIES,
        payload: data
    })
}

export const _setAuctionsAction = (data) => {
    return dispatch => dispatch({
        type: SET_AUCTIONS,
        payload: data
    })
}