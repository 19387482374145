export const CURRENT_PAGE_ROUTE_URL = "currentPage"
export const EMPTY_NULL_DATA = 'N/A';
export const CURRENCY_SYMBOL = "¥"
export const CURRENCY_ABBREVIATION = "CNY"



export const statusConditionalColors = {
    bidStautsBgColorLightBlue: "lightblue",
    bidStautsBgColorLightGreen: "lightgreen",
    bidStautsBgLightRed: "lightcoral",
    bidStautsBgLightYellow: "lightyellow",
    bidStautsBgMuddy: "#8B7355",
}

