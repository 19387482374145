import client from "../config"

export const getHomeCategoriesAPI = async ({limit = ""}) => {
    return await client.get(`/categories?limit=${limit}&page=1`)
};

export const getAllCategoriesAPI = async (limit, page) => {
    return await client.get(`/categories?limit=${limit}&page=${page}`)
};

export const getFeaturedAuctionsAPI = async (categoryId, search) => {
    return await client.get(`/auctions?page=1&isSoon=&filterType=&categoryId=${categoryId}&search=${search}&limit=10`)
}