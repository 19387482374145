import client from "../config";

export const getAddressesAPI = async () => {
  return await client.get(`/application/addresses`);
};

export const postAddressAPI = async (data) => {
  return await client.post(`/application/addresses`, data);
};

export const getSingleAddressApi = async (id) => {
  return await client.get(`/application/addresses/${id}`);
};

export const updateAddressApi = async (data) => {
  return await client.put(`/application/addresses`, data);
};

export const deleteAddressApi = async (id) => {
  return await client.delete(`/application/addresses/${id}`);
};
