import AppRoutes from "./routes/Routes";
import { BrowserRouter } from "react-router-dom";
import AppHeader from "./components/Header/Header";
import AppFooter from "./components/Footer/Footer";
import { ConfigProvider } from "antd";
import { theme } from "./theme/Theme";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";

import "./styles/Fonts.css";
import "./App.css";
import { persistor, Store } from "./redux/store/Store";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

function App() {
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();

  const handleLanguageChange = async (language) => {
    window.location.reload();
  };
  useEffect(() => {
    i18n.on("languageChanged", handleLanguageChange);

    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [i18n]);

  return (
    <Provider store={Store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConfigProvider theme={theme}>
          <NotificationContainer />
          <div>
            <AppHeader />
            <body style={{ minHeight: "70vh", background: "#F5F6F9" }}>
              <BrowserRouter>
                <ScrollToTop />
                <AppRoutes />
                <AppFooter />
              </BrowserRouter>
            </body>
          </div>
        </ConfigProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
