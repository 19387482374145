import { Row} from "antd";
import React from "react";
import style from "./style.module.scss";
import CustomModal from '../CustomModal/CustomModal'
import CustomButton from "../custom-button/CustomButton";

const CommonConfirmationModal = ({
  visibleModal,
  handleCancel,
  title = "Confirm",
  handleOk = () => {},
  heading = "Are you sure!",
  para = "You knwo what you are going for so are you still agree",
  confimBtnTitle = "Confirm",
  loadingConfirmBtn,
  onClickConfirm,
  cancelBtnTitle = "Cancel",
  onClickCancel,
}) => {
  return (
    <CustomModal
      visibility={visibleModal}
      handleCancel={handleCancel}
      className={style.deleteModal}
      handleOk={handleOk}
      title={title}
    >
      <div className={style.commonDeleteModalContainer}>
          <Row className={style?.heading}>
            <h4>{heading}</h4>
          </Row>
          <Row className={style?.para}>
            <p> {para} </p>
          </Row>
          <Row className={style?.btnsRow}>
            {cancelBtnTitle && (
              <CustomButton
                title={cancelBtnTitle}
                onClick={onClickCancel}
                variant={"outlined"}
              />
            )}
            {confimBtnTitle && (
              <CustomButton
                loading={loadingConfirmBtn}
                title={confimBtnTitle}
                onClick={onClickConfirm}
              />
            )}
          </Row>
      </div>
    </CustomModal>
  );
};

export default CommonConfirmationModal;
