import {
  SET_WATCHLIST,
  ADD_TO_WATCHLIST,
  REMOVE_FROM_WATCHLIST,
} from "../../actions/watchlist/WatchListActions";

const initialState = {
  data: [],
};

const WatchlistReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WATCHLIST:
      return {
        ...state,
        data: action.payload,
      };
    case ADD_TO_WATCHLIST:
      return {
        ...state,
        data: [...state.data, action.payload],
      };
    case REMOVE_FROM_WATCHLIST:
      console.log("in remove watchlist >>>>>>", action.payload);
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.payload.id),
      };
    default:
      return state;
  }
};

export default WatchlistReducer;
