import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
// import { CREDENTIALS } from "../constants";

const Protected = ({ children }) => {
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const location = useLocation();
  if (isLoggedIn === null || isLoggedIn === undefined || isLoggedIn === "") {
    return <Navigate to="/" replace state={{ path: location.pathname }} />;
  }
  return children;
};
export default Protected;
