import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import { Col, Row } from "antd";
import LeftArrow from "../../assets/left-arrow.svg";
import useWindowSize from "../../utils/hooks/useWindowSize";
import { useNavigate } from "react-router-dom";
import ProtectedHeader from "../../components/ProtectedHeader/ProtectedHeader";
import CustomButton from "../../components/CommonComponents/custom-button/CustomButton";
import { payToBidAPI } from "../../apis/bids/BidAPI";
import {
  formatDate,
  renderItemDataOrEmptyNull,
  requestErrorHandel,
  successMessage,
} from "../../utils/common-functions";
import { getAddressesAPI } from "../../apis/Addresses";
import Loader from "../../components/Loader/Loader";
import { getCompanyAPI } from "../../apis/company";
import { constRoute } from "../../utils/Constants";
import { CURRENCY_SYMBOL } from "../../utils/const";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Checkout = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const myBidsData = useSelector((state) => state?.bids?.singleBidData);
  const [loading, setLoading] = useState(false);
  const [address, setAddresses] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [companyData, setCompanyData] = useState(null);
  const [addressId, setAddressId] = useState(null);

  useEffect(() => {
    getAllAddress();
    getCompany();
  }, []);

  useEffect(() => {
    if (companyData) {
      setGrandTotal(
        myBidsData?.bidStatuses?.myBidPrice +
          myBidsData?.deliveryFee +
          (Number(companyData?.serviceCharges?.value) / 100) *
            myBidsData?.bidStatuses?.myBidPrice
      );
    }
  }, [companyData, myBidsData]);

  const getAllAddress = async () => {
    setLoading(true);
    try {
      const response = await getAddressesAPI();
      if (response?.data?.statusCode === 200) {
        setAddresses(response?.data?.data);
        setAddressId(response?.data?.data[0]?.id);
      }
    } catch (error) {
      requestErrorHandel(error);
    } finally {
      setLoading(false);
    }
  };

  const getCompany = async () => {
    setLoading(true);
    try {
      const response = await getCompanyAPI();
      if (response?.data?.statusCode === 200) {
        setCompanyData(response?.data?.data);
      }
    } catch (error) {
      requestErrorHandel(error);
    } finally {
      setLoading(false);
    }
  };

  const onPayToProceed = async () => {
    try {
      const payload = {
        bidId: myBidsData?.winnerBidId,
        amount: grandTotal,
        addressId: addressId,
      };
      const result = await payToBidAPI(payload);
      if (result.data.data && result.data.statusCode === 200) {
        successMessage(t("bidHasBeenProceedToPay"));
        navigate(constRoute?.myBids);
      } else throw result.data;
    } catch (e) {
      requestErrorHandel(e);
    }
  };

  return (
    <>
      <div className={style.headerContainer}>
        <ProtectedHeader />
      </div>
      <Loader loading={loading} />
      <div className={style.pageContainer}>
        <div className={style.fluidContainer}>
          <Row justify={width <= 768 ? "center" : "left"}>
            {width <= 768 && (
              <img
                onClick={() => navigate("/")}
                className={style.arrowIcon}
                src={LeftArrow}
                width={20}
                alt=""
              />
            )}
            <h1>{t("checkout")}</h1>
          </Row>
          <Row gutter={32} className={style.fluidRow}>
            <Col md={12} sm={24} xs={24}>
              <Row className={style.leftRow}>
                <Row className={style.imgRow}>
                  <img
                    src={
                      myBidsData?.files?.find(
                        (item) => item?.fileType === "image"
                      )?.file
                    }
                    alt=""
                  />
                  <div>
                    <h4> {renderItemDataOrEmptyNull(myBidsData?.title)} </h4>
                    <div className={style.paraDiv}>
                      <p>
                        {t(`auctionDetails.payBefore`)}:
                        {formatDate(myBidsData?.bidStatuses?.payBefore)}
                      </p>
                    </div>
                  </div>
                </Row>

                {(address?.length &&
                  address?.map((item, index) => {
                    if (index == localStorage.getItem("selectedAddress")) {
                      return (
                        <Row
                          onClick={() => {
                            setAddressId(item?.id);
                          }}
                          className={style.addressRow}
                        >
                          <h6>{t("address")}</h6>
                          <p>
                            {renderItemDataOrEmptyNull(
                              `${item?.title}, ${item?.addressLine1}, ${item?.addressLine2}, ${item?.city}, ${item?.state}, ${item?.country?.name}`
                            )}
                          </p>
                          <Row justify="end">
                            <CustomButton
                              title={t("change")}
                              onClick={() =>
                                navigate(constRoute.myDeliveryAddress)
                              }
                            />
                          </Row>
                        </Row>
                      );
                    } else
                      return (
                        <Row
                          onClick={() =>
                            navigate(constRoute?.myDeliveryAddress)
                          }
                          className={style.addressRow}
                        >
                          <h6>{t("address")}</h6>
                          <p>{t("selectAddressToContinue")}</p>
                        </Row>
                      );
                  })) || (
                  <Row
                    onClick={() => navigate(constRoute?.myDeliveryAddress)}
                    className={style.addressRow}
                  >
                    <h6>{t("address")}</h6>
                    <p>{t("addAddressToContinue")}</p>
                  </Row>
                )}
              </Row>
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Row className={style.rightRow}>
                <h6>{t("payment")}</h6>
                <Row justify={"space-between"}>
                  <p>{t("biddingAmount")}</p>
                  <p>
                    {CURRENCY_SYMBOL}
                    {renderItemDataOrEmptyNull(
                      myBidsData?.bidStatuses?.myBidPrice,
                      2
                    )}
                  </p>
                </Row>
                <Row justify={"space-between"}>
                  <p>{t("serviceCharges")}</p>
                  <p>
                    {CURRENCY_SYMBOL}
                    {renderItemDataOrEmptyNull(
                      (Number(companyData?.serviceCharges?.value) / 100) *
                        myBidsData?.bidStatuses?.myBidPrice,
                      2
                    )}
                  </p>
                </Row>
                <Row justify={"space-between"}>
                  <p>{t("deliveryFees")}</p>
                  <p>
                    {CURRENCY_SYMBOL}
                    {renderItemDataOrEmptyNull(myBidsData?.deliveryFee, 2)}
                  </p>
                </Row>
                <Row justify={"space-between"}>
                  <h4>{t("grandTotal")}</h4>
                  <h4>
                    {CURRENCY_SYMBOL}
                    {renderItemDataOrEmptyNull(grandTotal, 2)}
                  </h4>
                </Row>
                <Row justify={"center"}>
                  <CustomButton
                    onClick={() => {
                      onPayToProceed();
                    }}
                    disabled={
                      localStorage.getItem("selectedAddress") === null ||
                      localStorage.getItem("selectedAddress") === "null" ||
                      address?.length < 1
                    }
                    title={t("proceedToPay")}
                  />
                </Row>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Checkout;
