import { useMediaQuery } from "@uidotdev/usehooks";
import { Card, Col, Radio, Row, Space } from "antd";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllAuctionsAPI } from "../../apis/auctions/AuctionsAPI";
import HomeAuctionList from "../../components/HomeAuctionList/HomeAuctionList";
import ProtectedHeader from "../../components/ProtectedHeader/ProtectedHeader";
import { _setAllAuctionsAction } from "../../redux/actions/auctions/AuctionsActions";
import filterIcon from "../../assets/filter-icon.svg";
import backIcon from "../../assets/back-arrow-icon.svg";
import "./Auctions.css";
import { useLocation, useNavigate } from "react-router-dom";
import BottomSheet from "../../components/BottomSheet/BottomSheet";
import Loader from "../../components/Loader/Loader";
import { useTranslation } from "react-i18next";

const Auctions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const auctions = useSelector((state) => state.auctions.auctionsData);
  const location = useLocation();
  const category = location.state?.category;
  const search = location.state?.search;
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  const [loading, setLoading] = useState(false);

  const [openFilterSheet, setOpenFilterSheet] = useState(false);

  const [priceFilter, setPriceFilter] = useState("");
  const [bidsFilter, setBidsFilter] = useState("");
  const [dateFilter, setDateFilter] = useState("");
  const [gradeFilter, setGradeFilter] = useState("");
  const [endingFilter, setEndingFilter] = useState("");

  const fetchAuctions = useCallback(async () => {
    try {
      setLoading(true);

      const result = await getAllAuctionsAPI(
        priceFilter,
        // bidsFilter,
        // gradeFilter,
        // dateFilter,
        // endingFilter,
        category?.id,
        search
      );
      if (result.data?.statusCode === 200 && result.data.data) {
        dispatch(_setAllAuctionsAction(result.data.data));
        setLoading(false);
      } else throw result.data.data;
    } catch (e) {
      setLoading(false);
      console.log("error in fetch auctions...", e);
    }
  }, [
    dispatch,
    priceFilter,
    // bidsFilter,
    // dateFilter,
    // gradeFilter,
    // endingFilter,
    category,
    search,
  ]);

  useEffect(() => {
    fetchAuctions();
  }, [fetchAuctions]);

  const onChange = (groupName) => (e) => {
    const value = e.target.value;
    switch (groupName) {
      case "price":
        setPriceFilter(value);
        break;
      case "bids":
        setBidsFilter(value);
        setPriceFilter(value);
        break;
      case "date":
        setDateFilter(value);
        setPriceFilter(value);
        break;
      case "grade":
        setGradeFilter(value);
        setPriceFilter(value);
        break;
      case "isSoon":
        setEndingFilter(value);
        setPriceFilter(value);
        break;
      default:
    }
  };

  const goBack = () => navigate(-1);

  const onOpenBottomSheet = () => setOpenFilterSheet(true);

  const resetFilters = () => {
    setPriceFilter("");
    setBidsFilter("");
    setDateFilter("");
    setEndingFilter("");
    setGradeFilter("");
  };

  const renderFilters = () => {
    return (
      <Card>
        <Row justify={"space-between"}>
          <p className="semiBold__font">{t(`auctions.viewBy`)}</p>
          <p className="t-red semiBold__font t-cursor" onClick={resetFilters}>
            {t(`auctions.reset`)}
          </p>
        </Row>
        {/* price */}
        <p className="t-slate-light medium__font">{t(`filter.price`)}</p>
        <Radio.Group onChange={onChange("price")} value={priceFilter}>
          <Space direction="vertical">
            <Radio value={"lowPrice"}>{t(`filter.lowToHigh`)}</Radio>
            <Radio value={"highPrice"}>{t(`filter.highToLow`)}</Radio>
          </Space>
        </Radio.Group>

        {/* Bids */}
        <p className="t-slate-light medium__font">{t(`filter.bids`)}</p>
        <Radio.Group onChange={onChange("bids")} value={bidsFilter}>
          <Space direction="vertical">
            <Radio value={"lowBids"}>{t(`filter.lowBids`)}</Radio>
            <Radio value={"highBids"}>{t(`filter.highBids`)}</Radio>
          </Space>
        </Radio.Group>

        {/* Added Date */}
        <p className="t-slate-light medium__font">{t(`filter.addedDate`)}</p>
        <Radio.Group onChange={onChange("date")} value={dateFilter}>
          <Space direction="vertical">
            <Radio value={"latestDate"}>{t(`filter.latest`)}</Radio>
            <Radio value={"oldestDate"}>{t(`filter.oldest`)}</Radio>
          </Space>
        </Radio.Group>

        {/* Grade */}
        <p className="t-slate-light medium__font">{t(`filter.grade`)}</p>
        <Radio.Group onChange={onChange("grade")} value={gradeFilter}>
          <Space direction="vertical">
            <Radio value={"lowGrade"}>{t(`filter.lowToHigh`)}</Radio>
            <Radio value={"highGrade"}>{t(`filter.highToLow`)}</Radio>
          </Space>
        </Radio.Group>

        {/* Auction Ending */}
        <p className="t-slate-light medium__font">
          {t(`filter.auctionEnding`)}
        </p>
        <Radio.Group onChange={onChange("isSoon")} value={endingFilter}>
          <Space direction="vertical">
            <Radio value={"1"}>{t(`filter.soon`)}</Radio>
          </Space>
        </Radio.Group>
      </Card>
    );
  };

  return (
    <div className="common-layout all-auction-main">
      <Loader loading={loading} />
      <ProtectedHeader />
      <section>
        <Row
          justify={"space-between"}
          align={"middle"}
          className={"auction-header-row"}
        >
          {isSmallDevice && (
            <button onClick={goBack}>
              <img src={backIcon} alt={"back"} />
            </button>
          )}
          <h2 className="bold__font">
            {" "}
            {search
              ? `${t("searchedBy")}: ${search}`
              : category?.title
              ? `${t("category")}: ${category?.title}`
              : t(`auctions.auctions`)}
          </h2>
          {isSmallDevice && (
            <button onClick={onOpenBottomSheet}>
              <img src={filterIcon} alt={"filter"} />
            </button>
          )}
        </Row>
        <Row className="auction-row" gutter={[20]}>
          {!isSmallDevice && (
            <Col md={6} className="filter-section">
              {renderFilters()}
            </Col>
          )}
          <Col md={18} xs={24}>
            <HomeAuctionList data={auctions} type={"auctions-page"} />
          </Col>
        </Row>

        <BottomSheet open={openFilterSheet}>{renderFilters()}</BottomSheet>
      </section>
    </div>
  );
};

export default Auctions;
