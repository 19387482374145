import { useMediaQuery } from "@uidotdev/usehooks";
import { Card, Row } from "antd";
import { useTranslation } from "react-i18next";

// import TMobileLogo from "../../assets/t-mobile.png";
// import OrangeLogo from "../../assets/orange-3.png";
import JadeLogo from "../../assets/logo.png";
// import VerizonLogo from "../../assets/verizon-3.png";
// import { useSelector } from "react-redux";

const HomeCategories = ({ data, selected, onSelect, type, ...props }) => {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const { t } = useTranslation();

  const categories = [
    { id: "allAuctions", image: JadeLogo, title: `${t("allAuctions")}` }  ];
  return (
    <Row
      className="categories-main"
      style={{ flexWrap: type === "all" ? "wrap" : "nowrap" }}
      justify={isSmallDevice && type === "all" ? "center" : ""}
    >
      {categories?.map((el) => (
        <div className={"category-item"} key={el.id}>
          <Card
            bordered={false}
            hoverable
            className={`category-card ${
              el.id === selected ? "selected-category" : ""
            }`}
            onClick={() =>
              el.id !== selected ? onSelect(el.id, el.title) : null
            }
          >
            <img alt={"category"} src={el.image} />
          </Card>
          <p className="bold__font t-center">{el.title}</p>
        </div>
      ))}
    </Row>
  );
};
export default HomeCategories;
