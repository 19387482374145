import React from "react";
import style from "./style.module.scss";
import { Col, Row } from "antd";
import LeftArrow from "../../assets/left-arrow.svg";
import useWindowSize from "../../utils/hooks/useWindowSize";
import { useNavigate } from "react-router-dom";
import ProtectedHeader from "../../components/ProtectedHeader/ProtectedHeader";
import InfoIcon from "../../assets/info-circle.svg";
import CustomButton from "../../components/CommonComponents/custom-button/CustomButton";

const PayByAccount = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  return (
    <>
      <div className={style.headerContainer}>
        <ProtectedHeader />
      </div>

      <div className={style.pageContainer}>
        <div className={style.fluidContainer}>
          <Row justify={width <= 768 ? "center" : "left"}>
            {width <= 768 && (
              <img
                onClick={() => navigate("/")}
                className={style.arrowIcon}
                src={LeftArrow}
                width={20}
                alt=""
              />
            )}
            <h1>Pay By Account</h1>
          </Row>
          <Row gutter={25} className={style.fluidRow}>
            <Col md={12} sm={24} xs={24}>
              <div className={style.leftColContainer}>
                <div>
                  <h4>Jade LLC</h4>
                  <p>Account Name</p>
                </div>
                <div>
                  <h4>Dubai Bank UAE</h4>
                  <p>Bank Name</p>
                </div>
                <div>
                  <h4>IBAN</h4>
                  <p>DB2131234234345345</p>
                </div>
                <div>
                  <p>
                    Deposit the money in Emirates Auctions account and upload
                    the receipt here.
                  </p>
                </div>
                <div className={style.btnContainer}>
                  <CustomButton title={"Upload Payment Receipt"} />
                </div>
              </div>
            </Col>
            <Col md={12} sm={24} xs={24}>
              <div className={style.rightColContainer}>
                <Row className={style.rightMainRow}>
                  <Col span={24}>
                    <Row className={style.rightInfoRow}>
                      <img src={InfoIcon} alt="" />
                      <p>
                        For Bidding: Lorem ipsum dolor sit amet consectetur
                        adipiscing elit facilisi, egestas laoreet id suscipit
                        erat nibh tortor Eu natoque interdum metus pretium
                        turpis placerat. Leo For Bidding: Lorem ipsum dolor sit
                        amet consectetur adipiscing elit facilisi, egestas
                        laoreet id suscipit erat nibh tortor Eu natoque interdum
                        metus pretium turpis placerat. Leo
                      </p>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <div className={style.rightColContainer}>
                      <h4>Dubai Headoffice</h4>
                      <p>
                        <b>Address:</b> Box No. 42981, Sharjah,Emirates
                      </p>
                      <p>
                        <b>Telephone: </b> <u>+971285863</u> <u> +971285863 </u>
                      </p>
                      <p>
                        <b>Working Hours:</b> Monday - Thursday 8:00am - 5:00pm
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default PayByAccount;
