import client from "../config"

export const getAllAuctionsAPI = async (price, categoryId = "", search = "") => {
    return await client.get(`/auctions?page=1&type=${price}&categoryId=${categoryId}&search=${search}&limit=1000`)
}

// return await client.get(`/auctions?page=1&type=${price}&bids=${bid}&grade=${grade}&date=${date}&isSoon=${ending}&categoryId=${categoryId}&search=${search}&limit=1000`)


export const getAllAuctionsByCategoryAPI = async (categoryId) => {
    return await client.get(`/auctions?page=1&isSoon=&filterType=&categoryId=${categoryId}&limit=1000`)
}

export const getSingleAuctionsAPI = async (id) => {
    return await client.get(`/auctions/${id}`)
}