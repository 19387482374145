import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import AuctionDetail from "../screens/AuctionDetail/AuctionDetail";
import Auctions from "../screens/Auctions/Auctions";
import Home from "../screens/Home/Home";
import Login from "../screens/Login/Login";
import MyBids from "../screens/MyBids/MyBids";
import Signup from "../screens/Signup/Signup";
import VerifyOTP from "../screens/VerifyOTP/VerifyOTP";
import Protected from "./ProtectedRoutesWrapper";
import AboutUs from "../screens/AboutUs/index";
import { constRoute } from "../utils/Constants";
import TermsAndCondition from "../screens/TermsAndConditions";
import PrivacyPolicy from "../screens/PrivacyPolicy";
import ContactUS from "../screens/ContactUs";
import NotFound from "../screens/NotFound";
import UpdatePassword from "../screens/UpdatePassword";
import UpdateProfile from "../screens/UpdateProfile/UpdateProfile";
import WatchList from "../screens/Watchlist/WatchlistScreen";
import OnlineCreditCard from "../screens/OnlineCreditCard";
import AllCategories from "../screens/AllCategories/AllCategories";
import AllAuctions from "../screens/AllAuctions/AllAuctions";
import PayByAccount from "../screens/PayByAccount";
import PayByCheque from "../screens/PayByCheque";
import Deposit from "../screens/Deposit";
import UploadReceipt from "../screens/UploadReciept";
import AddNewAddress from "../screens/AddNewAddress";
import MyDeliveryAddress from "../screens/MyDeliveryAddress";
import Checkout from "../screens/Checkout";
import { useSelector } from "react-redux";
import ForgotPassowordPhone from "../screens/ForgotPassword/ForgotPasswordPhone";
import ForgotPasswordVerify from "../screens/ForgotPassword/ForgotPasswordVerify";
import ForgotPasswordUpdate from "../screens/ForgotPassword/ForgotPasswordUpdate";
import axios from "axios";
import MobileNotifications from "../screens/MobileNotifications/MobileNotifications";
import AuctionsTermsAndCondition from "../screens/AuctionsT&C/AuctionsT&C";

const ProtectedRoutes = [
  {
    path: "/auctions",
    element: <Auctions />,
  },
  {
    path: "/auction-detail/:id",
    element: <AuctionDetail />,
  },
  {
    path: "/auction-detail/:id/terms&conditions",
    element: <AuctionsTermsAndCondition/>
  },
  {
    path: constRoute.allAuctions,
    element: <AllAuctions />,
  },
  {
    path: "/my-bids",
    element: <MyBids />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: constRoute?.updatePassword,
    element: <UpdatePassword />,
  },
  {
    path: constRoute?.updateProfile,
    element: <UpdateProfile />,
  },
  {
    path: constRoute.watchlist,
    element: <WatchList />,
  },
  {
    path: constRoute.onlineCreditCard,
    element: <OnlineCreditCard />,
  },
  {
    path: constRoute.payByAccount,
    element: <PayByAccount />,
  },
  {
    path: constRoute.payByCheque,
    element: <PayByCheque />,
  },
  {
    path: constRoute.uploadReceipt,
    element: <UploadReceipt />,
  },
  {
    path: constRoute.deposit,
    element: <Deposit />,
  },
  {
    path: constRoute.addNewAddress,
    element: <AddNewAddress />,
  },
  {
    path: constRoute.myDeliveryAddress,
    element: <MyDeliveryAddress />,
  },
  {
    path: constRoute.checkout,
    element: <Checkout />,
  },
  {
    path: constRoute.login,
    element: <Login />,
  },
  {
    path: constRoute.signup,
    element: <Signup />,
  },
  {
    path: constRoute.verifyOTP,
    element: <VerifyOTP />,
  },
  {
    path: constRoute.home,
    element: <Home />,
  },
  {
    path: constRoute.allCategories,
    element: <AllCategories />,
  },
  {
    path: constRoute.notification,
    element: <MobileNotifications />
  },
  {
    path: constRoute.aboutUs,
    element: <AboutUs />,
  },
  {
    path: constRoute.contactUs,
    element: <ContactUS />,
  },
  {
    path: constRoute.termsAndConditions,
    element: <TermsAndCondition />,
  },
  {
    path: constRoute.privacyPolicy,
    element: <PrivacyPolicy />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

const UnProtectedRoutes = [
  {
    path: "/auctions",
    element: <Auctions />,
  },
  {
    path: "/auction-detail/:id",
    element: <AuctionDetail />,
  },
  {
    path: "/auction-detail/:id/terms&conditions",
    element: <AuctionsTermsAndCondition/>
  },
  {
    path: constRoute.allAuctions,
    element: <AllAuctions />,
  },
  {
    path: constRoute.login,
    element: <Login />,
  },
  {
    path: constRoute.signup,
    element: <Signup />,
  },
  {
    path: constRoute.forgotPasswordPhone,
    element: <ForgotPassowordPhone />,
  },
  {
    path: constRoute.forgotPasswordOtp,
    element: <ForgotPasswordVerify />,
  },
  {
    path: constRoute.forgotPassword,
    element: <ForgotPasswordUpdate />,
  },
  {
    path: constRoute.verifyOTP,
    element: <VerifyOTP />,
  },
  {
    path: constRoute.home,
    element: <Home />,
  },
  {
    path: constRoute.allCategories,
    element: <AllCategories />,
  },
  {
    path: constRoute.aboutUs,
    element: <AboutUs />,
  },
  {
    path: constRoute.contactUs,
    element: <ContactUS />,
  },
  {
    path: constRoute.termsAndConditions,
    element: <TermsAndCondition />,
  },
  {
    path: constRoute.privacyPolicy,
    element: <PrivacyPolicy />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

const AppRoutes = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const user = useSelector((state) => state.auth.user);


  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          await reverseGeocode(position.coords.latitude, position.coords.longitude)
        },
        (error) => {
          console.error("Error getting user location:", error.message);
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  }, []);

  const reverseGeocode = async (latitude, longitude) => {
    try {
      const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
      const response = await axios.get(apiUrl);
      if (
        response.data &&
        response.data.address &&
        response.data.address.country
      ) {
        const country = response.data.address.country;
        await localStorage.setItem('country', country);
      } else {
        console.log('Unable to determine country');
      }
    } catch (error) {
      console.log('Error in reverse geocoding:', error);
    }
  };


  return (
    <Routes>
      {!isLoggedIn &&
        UnProtectedRoutes.map((route) => (
          <Route path={route.path} element={route.element} />
        ))}
      {isLoggedIn && !(user?.accountStatus === "approved") &&
        UnProtectedRoutes.map((route) => (
          <Route path={route.path} element={route.element} />
        ))}
      {isLoggedIn && user?.accountStatus === "approved" &&
        ProtectedRoutes.map((route) => (
          <Route
            path={route.path}
            element={<Protected>{route.element}</Protected>}
          />
        ))}
    </Routes>
  );
};

export default AppRoutes;
