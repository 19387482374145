import React, { useState } from "react";
import style from "./style.module.scss";
import { Col, Row } from "antd";
import LeftArrow from "../../assets/left-arrow.svg";
import useWindowSize from "../../utils/hooks/useWindowSize";
import { useNavigate } from "react-router-dom";
import ProtectedHeader from "../../components/ProtectedHeader/ProtectedHeader";
import { deleteAddressApi, getAddressesAPI } from "../../apis/Addresses";
import Loader from "../../components/Loader/Loader";
import {
  renderItemDataOrEmptyNull,
  requestErrorHandel,
  successMessage,
} from "../../utils/common-functions";
import { useEffect } from "react";
import CommonConfirmationModal from "../../components/CommonComponents/common-Confirmation-modal";
import { constRoute } from "../../utils/Constants";
import CustomButton from "../../components/CommonComponents/custom-button/CustomButton";
import { useTranslation } from "react-i18next";

const MyDeliveryAddress = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const { t } = useTranslation();
  const [address, setAddresses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [deletingId, setDeletingId] = useState(null);
  const [activeAddressIndex, setActiveAddressIndex] = useState(0);

  const getAllAddress = async () => {
    setLoading(true);
    try {
      const response = await getAddressesAPI();
      if (response?.data?.statusCode === 200) {
        setAddresses(response?.data?.data);
      }
    } catch (error) {
      requestErrorHandel(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllAddress();
  }, []);

  const removeAddress = async () => {
    setLoading(true);
    try {
      const response = await deleteAddressApi(deletingId);
      if (response?.data?.statusCode === 200) {
        successMessage(t("addressHasBeenRemoved"));
        setVisibleDelete(false);
        getAllAddress();
      }
    } catch (error) {
      requestErrorHandel(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={style.headerContainer}>
        <ProtectedHeader />
      </div>
      <Loader loading={loading} />

      <CommonConfirmationModal
        title={t("removeAddress")}
        heading=""
        para={t("areYouSureYouAreGoingToRemoveTheAddress")}
        visibleModal={visibleDelete}
        handleCancel={() => setVisibleDelete(false)}
        confimBtnTitle={t("confirm")}
        onClickConfirm={() => removeAddress()}
        cancelBtnTitle={t("cancel")}
        onClickCancel={() => setVisibleDelete(false)}
      />

      <div className={style.pageContainer}>
        <div className={style.fluidContainer}>
          <Row justify={width <= 768 ? "center" : "left"}>
            {width <= 768 && (
              <img
                onClick={() => navigate("/")}
                className={style.arrowIcon}
                src={LeftArrow}
                width={20}
                alt=""
              />
            )}
            <h1>{t("myDeliveryAddress")}</h1>
          </Row>
          <Row gutter={32} className={style.fluidRow}>
            {(address?.length &&
              address?.map((item, index) => {
                return (
                  <Col md={12} sm={24} xs={24}>
                    <Row
                      className={
                        activeAddressIndex === index
                          ? style.rightRowActive
                          : style.rightRow
                      }
                      onClick={() => setActiveAddressIndex(index)}
                    >
                      <div className={style.headingRow}>
                        <h6>{renderItemDataOrEmptyNull(item?.title)}</h6>
                        <Row className={style.leftHeadingBtnRow} gutter={20}>
                          <h6
                            onClick={() => {
                              setVisibleDelete(true);
                              setDeletingId(item?.id);
                            }}
                          >
                            {t("remove")}
                          </h6>
                          <h6
                            onClick={() =>
                              navigate(constRoute?.addNewAddress, {
                                state: item?.id,
                              })
                            }
                          >
                            {t("edit")}
                          </h6>
                        </Row>
                      </div>
                      <p>
                        {renderItemDataOrEmptyNull(
                          `${item?.addressLine1}, ${item?.addressLine2}, ${item?.city}, ${item?.state}, ${item?.country?.name}`
                        )}
                      </p>
                    </Row>
                  </Col>
                );
              })) || (
              <Col md={12} sm={24} xs={24}>
                <Row className={style.leftRow}>
                  <p>{t("youDontHaveAnyAddress")}</p>
                </Row>
              </Col>
            )}
          </Row>
          <Row justify={"space-between"}>
            <CustomButton
              onClick={() => navigate(constRoute?.addNewAddress)}
              title={t("addAddressToContinue")}
            />
            <CustomButton
              onClick={() => {
                localStorage.setItem("selectedAddress", activeAddressIndex)
                navigate(constRoute.checkout)
              }}
              title={t("next")}
            />
          </Row>
        </div>
      </div>
    </>
  );
};

export default MyDeliveryAddress;
