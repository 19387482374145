import { Button, Col, Modal, Row } from "antd";
import React from "react";
import {
  FacebookShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  EmailIcon,
  WhatsappIcon,
} from "react-share";
import "./SocialShare.css";
import { CopyOutlined } from "@ant-design/icons";

const SocialShare = ({ visible, toggleVisibile, url, ...props }) => {
  const hideModal = () => toggleVisibile(false);
  const copyInfoToClipboard = () => {
    navigator.clipboard.writeText(url);
  };
  const buttonProps = { url: url, style: { marginRight: 10 } };
  const iconProps = { size: 30, round: true };
  const shareText = "Hey check out this auction on the Jade website";

  return (
    <Modal
      open={visible}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      className={"social-share-modal"}
      onCancel={hideModal}
    >
      <h3 className="bold__font">Share Auction</h3>
      {/* <Card> */}
      <Row
        justify={"space-between"}
        align={"middle"}
        className={"social-link-row"}
      >
        <Col md={20}>
          <p className="medium__font">{url}</p>
        </Col>
        <Button icon={<CopyOutlined />} onClick={copyInfoToClipboard}></Button>
      </Row>
      <FacebookShareButton {...buttonProps} quote={shareText}>
        <FacebookIcon {...iconProps} />
      </FacebookShareButton>
      <WhatsappShareButton {...buttonProps} title={shareText} separator={`: `}>
        <WhatsappIcon {...iconProps} />
      </WhatsappShareButton>
      <EmailShareButton {...buttonProps} subject={shareText}>
        <EmailIcon {...iconProps} />
      </EmailShareButton>
    </Modal>
  );
};

export default SocialShare;
