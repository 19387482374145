import { Form, message } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { forgotPasswordChangeAPI } from "../../apis/auth/AuthAPIs";
import Jade from "../../assets/logo.png";
import AppButton from "../../components/Button/Button";
import CommonTextInput from "../../components/Input/Input";
import { constRoute } from "../../utils/Constants";

const ForgotPasswordUpdate = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const { state } = useLocation();
  const phoneNo = state.phoneNo;
  const phoneToken = state.phoneToken;

  const submit = async (values) => {
    try {
      setLoading(true);
      const result = await forgotPasswordChangeAPI({
        phoneNo: phoneNo,
        phoneToken: phoneToken,
        newPassword: values.newPassword,
      });
      if (result.data.statusCode === 200) {
        messageApi.success(t(`forgotPassword.passwordResetSuccess`));
        setLoading(false);
        setTimeout(
          () =>
            navigate(constRoute.login, {
              replace: true,
            }),
          1000
        );
      } else return result.data;
    } catch (e) {
      console.log(e);
      setLoading(false);
      messageApi.error(
        e.response?.data?.errors?.password ??
          "An error occured while updating your password, please try again"
      );
    }
  };
  return (
    <div className="forgot-password-main">
      {contextHolder}
      <img width="40" src={Jade} alt={"logo"} />

      <Form form={form} onFinish={submit}>
        <h2>{t(`forgotPassword.newPassword`)}</h2>
        <p>{t(`forgotPassword.createPassword`)}</p>
        <Form.Item
          name={"newPassword"}
          rules={[
            {
              required: true,
              min: 8,
              message:
                "Please enter a password with minimum 8 characters containing letters, numbers and symbols",
            },
          ]}
        >
          <CommonTextInput
            type={"password"}
            placeholder={t(`forgotPassword.newPassword`)}
            required
          />
        </Form.Item>

        <Form.Item
          name={"repeatPassword"}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value === form.getFieldValue("newPassword")) {
                  return Promise.resolve();
                } else
                  return Promise.reject(new Error("Password does not match"));
              },
            }),
          ]}
        >
          <CommonTextInput
            type={"password"}
            placeholder={t(`forgotPassword.confirmPassword`)}
            required
          />
        </Form.Item>
        <AppButton type="primary" htmlType={"submit"} loading={loading}>
          {t(`forgotPassword.update`)}
        </AppButton>
      </Form>
    </div>
  );
};

export default ForgotPasswordUpdate;
