import React from "react";
import style from "./style.module.scss";
import { Col, Row } from "antd";
import LeftArrow from "../../assets/left-arrow.svg";
import useWindowSize from "../../utils/hooks/useWindowSize";
import { useNavigate } from "react-router-dom";
import ProtectedHeader from "../../components/ProtectedHeader/ProtectedHeader";
import InfoIcon from "../../assets/info-circle.svg";
import CallIcon from "../../assets/call.svg";

const PayByCheque = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  return (
    <>
      <div className={style.headerContainer}>
        <ProtectedHeader />
      </div>

      <div className={style.pageContainer}>
        <div className={style.fluidContainer}>
          <Row justify={width <= 768 ? "center" : "left"}>
            {width <= 768 && (
              <img
                onClick={() => navigate("/")}
                className={style.arrowIcon}
                src={LeftArrow}
                width={20}
                alt=""
              />
            )}
            <h1>Pay By Cheque</h1>
          </Row>
          <Row className={style.fluidRow}>
            <Col md={12} sm={24} xs={24}>
              <div className={style.leftColContainer}>
                <Row className={style.leftMainRow}>
                  <Col span={24}>
                    <Row className={style.leftInfoRow}>
                      <img src={CallIcon} alt="icon" />
                      <div>
                        <h4>123456 78</h4>
                        <p>Call</p>
                        <p className={style.visit}>
                          Please visit our office to submit your security
                          deposit cheque.
                        </p>
                      </div>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <div className={style.leftColContact}>
                      <Row justify={"space-between"}>
                        <h4>+971 123456789</h4>
                        <h4>+971 123456789</h4>
                      </Row>
                      <h4>+971 125893585</h4>
                      <p>Fax</p>
                      <h4>support@MK.ae</h4>
                      <p>Email</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={12} sm={24} xs={24}>
              <div className={style.rightColContainer}>
                <Row className={style.rightMainRow}>
                  <Col span={24}>
                    <Row className={style.rightInfoRow}>
                      <img src={InfoIcon} alt="" />
                      <p>
                        For Bidding: Lorem ipsum dolor sit amet consectetur
                        adipiscing elit facilisi, egestas laoreet id suscipit
                        erat nibh tortor Eu natoque interdum metus pretium
                        turpis placerat. Leo For Bidding: Lorem ipsum dolor sit
                        amet consectetur adipiscing elit facilisi, egestas
                        laoreet id suscipit erat nibh tortor Eu natoque interdum
                        metus pretium turpis placerat. Leo
                      </p>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <div className={style.rightColContainer}>
                      <h4>Dubai Headoffice</h4>
                      <p>
                        <b>Address:</b> Box No. 42981, Sharjah,Emirates
                      </p>
                      <p>
                        <b>Telephone: </b> <u>+971285863</u> <u> +971285863 </u>
                      </p>
                      <p>
                        <b>Working Hours:</b> Monday - Thursday 8:00am - 5:00pm
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default PayByCheque;
