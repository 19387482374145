import { useMediaQuery } from "@uidotdev/usehooks";
import { Card, Col, Empty, Radio, Row, Space } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchWatchlistAPI } from "../../apis/watchlist/WatchlistAPI";
import BottomSheet from "../../components/BottomSheet/BottomSheet";
import HomeAuctionList from "../../components/HomeAuctionList/HomeAuctionList";
import ProtectedHeader from "../../components/ProtectedHeader/ProtectedHeader";
import filterIcon from "../../assets/filter-icon.svg";
import backIcon from "../../assets/back-arrow-icon.svg";
import { _setWatchlistAction } from "../../redux/actions/watchlist/WatchListActions";
import { useTranslation } from "react-i18next";

const WatchList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const watchlist = useSelector((state) => state.watchlist.data);
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  const [openFilterSheet, setOpenFilterSheet] = useState(false);

  const [priceFilter, setPriceFilter] = useState("");
  const [bidsFilter, setBidsFilter] = useState("");
  const [dateFilter, setDateFilter] = useState("");
  const [gradeFilter, setGradeFilter] = useState("");
  const [endingFilter, setEndingFilter] = useState("");

  const fetchWatchlist = useCallback(async () => {
    try {
      const result = await fetchWatchlistAPI(
        priceFilter,
        bidsFilter,
        gradeFilter,
        dateFilter,
        endingFilter
      );
      if (result.data?.statusCode === 200 && result.data.data) {
        dispatch(_setWatchlistAction(result.data.data));
      }
    } catch (e) {
      console.log("error in fetch watchlist...", e);
    }
  }, [
    dispatch,
    bidsFilter,
    endingFilter,
    priceFilter,
    gradeFilter,
    dateFilter,
  ]);

  useEffect(() => {
    fetchWatchlist();
  }, [fetchWatchlist]);

  const onChange = (groupName) => (e) => {
    const value = e.target.value;
    switch (groupName) {
      case "price":
        setPriceFilter(value);
        break;
      case "bids":
        setBidsFilter(value);
        break;
      case "date":
        setDateFilter(value);
        break;
      case "grade":
        setGradeFilter(value);
        break;
      case "isSoon":
        setEndingFilter(value);
        break;
      default:
    }
  };

  const goBack = () => navigate(-1);

  const onOpenBottomSheet = () => setOpenFilterSheet(true);
  const resetFilters = () => {
    setPriceFilter("");
    setBidsFilter("");
    setDateFilter("");
    setEndingFilter("");
    setGradeFilter("");
  };

  const renderFilters = () => {
    return (
      <Card>
        <Row justify={"space-between"}>
          <p className="semiBold__font">{t(`auctions.viewBy`)}</p>
          <p className="t-red semiBold__font t-cursor" onClick={resetFilters}>
            {t(`auctions.reset`)}
          </p>
        </Row>
        {/* price */}
        <p className="t-slate-light medium__font">{t(`filter.price`)}</p>
        <Radio.Group onChange={onChange("price")} value={priceFilter}>
          <Space direction="vertical">
            <Radio value={"highPrice"}>{t(`filter.lowToHigh`)}</Radio>
            <Radio value={"lowPrice"}>{t(`filter.highToLow`)}</Radio>
          </Space>
        </Radio.Group>

        {/* Bids */}
        <p className="t-slate-light medium__font">{t(`filter.bids`)}</p>
        <Radio.Group onChange={onChange("bids")} value={bidsFilter}>
          <Space direction="vertical">
            <Radio value={"lowBids"}>{t(`filter.lowBids`)}</Radio>
            <Radio value={"highBids"}>{t(`filter.highBids`)}</Radio>
          </Space>
        </Radio.Group>

        {/* Added Date */}
        <p className="t-slate-light medium__font">{t(`filter.addedDate`)}</p>
        <Radio.Group onChange={onChange("date")} value={dateFilter}>
          <Space direction="vertical">
            <Radio value={"latestDate"}>{t(`filter.latest`)}</Radio>
            <Radio value={"oldestDate"}>{t(`filter.oldest`)}</Radio>
          </Space>
        </Radio.Group>

        {/* Grade */}
        <p className="t-slate-light medium__font">{t(`filter.grade`)}</p>
        <Radio.Group onChange={onChange("grade")} value={gradeFilter}>
          <Space direction="vertical">
            <Radio value={"highGrade"}>{t(`filter.lowToHigh`)}</Radio>
            <Radio value={"lowGrade"}>{t(`filter.highToLow`)}</Radio>
          </Space>
        </Radio.Group>

        {/* Auction Ending */}
        <p className="t-slate-light medium__font">
          {t(`filter.auctionEnding`)}
        </p>
        <Radio.Group onChange={onChange("isSoon")} value={endingFilter}>
          <Space direction="vertical">
            <Radio value={"1"}>{t(`filter.soon`)}</Radio>
          </Space>
        </Radio.Group>
      </Card>
    );
  };
  return (
    <div className="common-layout all-auction-main">
      <ProtectedHeader />
      <section>
        <Row
          justify={"space-between"}
          align={"middle"}
          className={"auction-header-row"}
        >
          {isSmallDevice && (
            <button onClick={goBack}>
              <img src={backIcon} alt={"back"} />
            </button>
          )}
          <h2 className="bold__font">{t(`watchlist.watchlist`)}</h2>
          {isSmallDevice && (
            <button onClick={onOpenBottomSheet}>
              <img src={filterIcon} alt={"filter"} />
            </button>
          )}
        </Row>
        <Row className="auction-row" gutter={[20]}>
          {!isSmallDevice && (
            <Col md={9} className="filter-section">
              {renderFilters()}
            </Col>
          )}
          <Col md={15} xs={24}>
            {watchlist.length > 0 ? (
              <HomeAuctionList data={watchlist} type={"auctions-page"} />
            ) : (
              <Empty
                description={
                  "Auctions you add to the watchlist will appear here."
                }
              />
            )}
          </Col>
        </Row>

        <BottomSheet open={openFilterSheet}>{renderFilters()}</BottomSheet>
      </section>
    </div>
  );
};

export default WatchList;
