import { Dropdown, Menu, Row, Space } from "antd";
import JadeLogo from "../../assets/logo.png";
import NotifIcon from "../../assets/notification-icon.svg";
import ProfileIcon from "../../assets/profile-icon.svg";
import DropdownIcon from "../../assets/dropdown-icon.svg";
import MenuIcon from "../../assets/menu-icon.svg";
import "./ProtectedHeader.css";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import MobileDrawer from "../MobileDrawer/MobileDrawer";
import { CREDENTIALS, constRoute, LANGUAGE } from "../../utils/Constants";
import CustomButton from "../CommonComponents/custom-button/CustomButton";
import { _setLoginAction, _updateProfileAction } from "../../redux/actions/auth/AuthActions";
import { capitalizeString, successMessage } from "../../utils/common-functions";
import i18n from "../../translation/i18nConfig";
import { useTranslation } from "react-i18next";
import NotificationList from "../NotificationsList/NotificationList";
import { getUserProfile } from "../../apis/auth/AuthAPIs";

const ProtectedHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const user = useSelector((state) => state.auth?.user);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const getUserProfileData = async () => {
    try {
      const result = await getUserProfile();
      if (result?.data?.statusCode === 200) {
        dispatch(_updateProfileAction(result?.data?.data));
      } else throw result;
    } catch (e) {
      console.log("errror in fetch country", e);
    }
  };

  useEffect(() => {
    getUserProfileData()
  }, []);

  const languageOptions = [
    {
      key: "en",
      label: t(`navMenu.english`),
    },
    // {
    //   key: "ar",
    //   label: t(`navMenu.arabic`),
    // },
    // {
    //   key: "sp",
    //   label: t(`navMenu.russian`),
    // },
    {
      key: "ch",
      label: t(`navMenu.chinese`),
    },
  ];

  const toggleDrawer = (toggle) => () => {
    setOpen(toggle);
  };

  const onLogout = () => {
    localStorage.removeItem(CREDENTIALS.AUTH_TOKEN);
    dispatch(_setLoginAction(false, undefined));
    navigate(constRoute?.home);
    successMessage("You have logged out successfully.");
  };

  const accountlist = [
    {
      key: "1",
      type: "group",
      label: t(`navMenu.generalMenu`),
      children: [
        {
          key: constRoute?.home,
          label: t(`navMenu.home`),
        },
        {
          key: constRoute?.aboutUs,
          label: t(`navMenu.aboutUs`),
        },
        {
          key: constRoute.auctions,
          label: t(`navMenu.auctions`),
        },
        {
          key: constRoute?.contactUs,
          label: t(`navMenu.contact`),
        },
      ],
    },
  ];

  const accountlistAuth = [
    {
      key: "1",
      type: "group",
      label: t(`navMenu.generalMenu`),
      children: [
        {
          key: constRoute?.home,
          label: t(`navMenu.home`),
        },
        {
          key: constRoute?.aboutUs,
          label: t(`navMenu.aboutUs`),
        },
        {
          key: constRoute?.auctions,
          label: t(`navMenu.auctions`),
        },
        {
          key: constRoute?.contactUs,
          label: t(`navMenu.contact`),
        },
      ],
    },
    {
      key: "1",
      type: "group",
      label: t(`navMenu.account`),
      children: [
        {
          key: constRoute?.notification,
          label: t("navMenu.notifications"),
        },
        {
          key: constRoute?.myBids,
          label: t("navMenu.myBids"),
        },
        {
          key: constRoute.watchlist,
          label: t("navMenu.watchlist"),
        },
        {
          key: constRoute?.deposit,
          label: t("navMenu.deposit"),
        },
        {
          key: constRoute?.updateProfile,
          label: t("navMenu.updateProfile"),
        },
        {
          key: constRoute?.updatePassword,
          label: t("navMenu.updatePassword"),
        },
      ],
    },
  ];

  const onClickItem = (e) => {
    navigate(e.key);
  };

  const onClickLanguageItem = (e) => {
    localStorage.setItem(LANGUAGE, e.key);
    i18n.changeLanguage(e.key);
  };

  return (
    <>
      <section className="protected-header-desktop">
        <header>
          <Row align={"middle"} justify={"space-between"}>
            <NavLink to={"/"}>
              <img
                className="main-home-logo"
                width="40"
                src={JadeLogo}
                alt={"logo"}
              />
            </NavLink>
            <div className="header-nav-row">
              <NavLink to={"/"} className="bold__font t-black">
                {t(`navMenu.home`)}
              </NavLink>
              <NavLink to="/about-us">{t(`navMenu.aboutUs`)}</NavLink>
              <NavLink to="/auctions">{t(`navMenu.auctions`)}</NavLink>
              <NavLink to={constRoute?.contactUs}>
                {t(`navMenu.contact`)}
              </NavLink>
              <Dropdown
                menu={{ items: languageOptions, onClick: onClickLanguageItem }}
                className={"language-dropdown"}
              >
                <div
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Space>
                    <p className="t-slate">
                      {languageOptions.find((el) => el.key === i18n.language)
                        ?.label ?? t(`navMenu.english`)}
                    </p>
                    <img src={DropdownIcon} alt={"down"} />
                  </Space>
                </div>
              </Dropdown>

              <Dropdown
                dropdownRender={(menu) => {
                  return <NotificationList isLoggedIn={isLoggedIn} />;
                }}
              >
                {/* <a href="/notifications"> */}
                <img
                  src={NotifIcon}
                  height={19}
                  alt={"notifications"}
                  style={{ margin: "0 0.5rem" }}
                />
                {/* </a> */}
              </Dropdown>

              <div className="caret" />
              <Dropdown
                menu={{
                  items: [],
                  selectable: true,
                  defaultSelectedKeys: [window?.location?.pathname],
                  onClick: onClickItem,
                }}
                dropdownRender={(menu) => (
                  <div className={"dropdown-render-container"}>
                    {isLoggedIn && !(user?.accountStatus === "approved") && (
                      <Row
                        justify={"space-between"}
                        className="account-status-header"
                      >
                        <p className="bold__font">
                          {t(`navMenu.accountStatus`)}
                        </p>
                        <p className="medium__font">
                          {capitalizeString(user?.accountStatus)}
                        </p>
                      </Row>
                    )}
                    <div>
                      {(isLoggedIn && user?.accountStatus === "approved"
                        ? accountlistAuth
                        : accountlist
                      )?.map((item) => {
                        return (
                          <div className={"menu-item-container"}>
                            <p className="menu-title-heading-h2">
                              {item?.label}
                            </p>
                            {item?.children?.map((child) => {
                              return (
                                <Menu
                                  onClick={onClickItem}
                                  className="custom-main-menu"
                                >
                                  <Menu.Item key={child?.key}>
                                    {child?.label}
                                  </Menu.Item>
                                </Menu>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                    {isLoggedIn ? (
                      <div className="logout">
                        <p onClick={onLogout}>{t(`navMenu.logout`)}</p>
                      </div>
                    ) : (
                      <div className={"dropdown-footer"}>
                        <p>{t(`navMenu.getFullAccess`)}</p>
                        <Row className="row-btns-container">
                          <CustomButton
                            onClick={() => navigate(constRoute?.login)}
                            title={t("login.sign-in")}
                          />
                          <CustomButton
                            onClick={() => navigate(constRoute?.signup)}
                            variant="outlined"
                            title={t(`signup.signup`)}
                          />
                        </Row>
                      </div>
                    )}
                  </div>
                )}
                className={"my-account-dropdown"}
              >
                <div onClick={(e) => e.preventDefault()}>
                  <Space>
                    <Row
                      className={"account-dropdown-row"}
                      align={"middle"}
                      justify={"space-between"}
                    >
                      <img src={ProfileIcon} alt={"profile"} height={18} />
                      <p className="t-slate">{t(`navMenu.myAccount`)}</p>
                      <img src={DropdownIcon} alt={"down"} />
                    </Row>
                  </Space>
                </div>
              </Dropdown>
            </div>
          </Row>
        </header>
      </section>

      <section className="protected-header-mobile">
        <MobileDrawer open={open} toggleDrawer={toggleDrawer} />
        <header>
          <Row align={"middle"} justify={"space-between"}>
            <img
              src={MenuIcon}
              width={20}
              alt={"menu"}
              onClick={toggleDrawer(true)}
            />
            <NavLink to={"/"}>
              <img
                className="main-home-logo"
                src={JadeLogo}
                height={25}
                alt={"logo"}
              />
            </NavLink>
            <img
              src={NotifIcon}
              width={20}
              alt={"notif"}
              onClick={() =>
                isLoggedIn ? navigate(constRoute.notification) : null
              }
            />
          </Row>
        </header>
      </section>
    </>
  );
};

export default ProtectedHeader;
