import React, { useState } from "react";
import style from "./style.module.scss";
import { Col, Form, Row } from "antd";
import LeftArrow from "../../assets/left-arrow.svg";
import useWindowSize from "../../utils/hooks/useWindowSize";
import { useLocation, useNavigate } from "react-router-dom";
import ProtectedHeader from "../../components/ProtectedHeader/ProtectedHeader";
import CustomButton from "../../components/CommonComponents/custom-button/CustomButton";
import CommonTextInput from "../../components/Input/Input";
import ImageInput from "../../components/ImageInput/ImageInput";
import { setRules } from "../../utils/common-functions";
import { constRoute } from "../../utils/Constants";
import { useTranslation } from "react-i18next";

const UploadReceipt = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { depositAmount } = location?.state;
  const [loading, setLoading] = useState(false);
  const [fileReceipt, setFileReceipt] = useState(null);
  const value = depositAmount.toFixed(0);
  const [date, setDate] = useState(new Date());

  const { width } = useWindowSize();

  const submit = (values) => {
    navigate(constRoute.deposit, {
      state: { date, file: fileReceipt, depositAmount },
    });
  };

  return (
    <div>
      <div className={style.headerContainer}>
        <ProtectedHeader />
      </div>
      <div className={style.updatePasswordContainer}>
        <div className={style.pageContainer}>
          <div className={style.fluidContainer}>
            <Form
              layout="vertical"
              className={style.cancelModalForm}
              form={form}
              onFinish={submit}
            >
              <Row justify={width <= 768 ? "center" : "left"}>
                {width <= 768 && (
                  <img
                    onClick={() => navigate("/")}
                    className={style.arrowIcon}
                    src={LeftArrow}
                    width={20}
                    alt=""
                  />
                )}
                <h1>{t("uploadReceipt")}</h1>
              </Row>

              <Row className={style.fluidRow} gutter={40}>
                <Col md={12} sm={24} xs={24}>
                  <ImageInput
                    placeholder={t("uploadImage")}
                    className={style.file}
                    value={fileReceipt}
                    onSelect={setFileReceipt}
                    setUploading={setLoading}
                    buttonText={t(`signup.upload`)}
                  />
                </Col>

                <Col md={12} sm={24} xs={24}>
                  <Form.Item name={"amount"}>
                    <CommonTextInput
                      defaultValue={Number(value) / 5}
                      disabled={true}
                      type="number"
                      placeholder={t("amount")}
                    />
                  </Form.Item>
                </Col>

                <Col md={12} sm={24} xs={24}>
                  <Form.Item name="date" rules={setRules(t("date"))}>
                    <CommonTextInput
                      type={"date"}
                      placeholder={t("selectDate")}
                      showTime
                      onChange={(e) => setDate(new Date(e))}
                    />
                  </Form.Item>
                </Col>

                <Col className={style.btnContainer} span={24}>
                  <CustomButton
                    htmlType="submit"
                    className={style.btn}
                    title="Submit"
                    disabled={!fileReceipt}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadReceipt;
