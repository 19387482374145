import { Spin } from "antd";
import "./Loader.css";

const Loader = (props) => {
  return (
    <div
      className="loader-main"
      style={{ visibility: props.loading ? "visible" : "hidden" }}
    >
      <div className="loader-wrapper">
        <Spin spinning={props.loading} className="loading-spinner" />
      </div>
    </div>
  );
};

export default Loader;
