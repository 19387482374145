import client from "../config"

export const fetchWatchlistAPI = async (
    price, bid, grade, date, ending, categoryId = "", search = ""
) => {
    return await client.get(`/auctions/watch/list?page=1&price=${price}&bids=${bid}&grade=${grade}&date=${date}&isSoon=${ending}&categoryId=${categoryId}&search=${search}&limit=1000`)
}

export const addToWatchListAPI = async (auctionId) => {
    return await client.post(`/auctions/watch/list/${auctionId}`)
}