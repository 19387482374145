import React from "react";
import style from "./style.module.scss";
import { Col, Row } from "antd";
import LeftArrow from "../../assets/left-arrow.svg";
import useWindowSize from "../../utils/hooks/useWindowSize";
import { useNavigate } from "react-router-dom";
import ProtectedHeader from "../../components/ProtectedHeader/ProtectedHeader";
import InfoIcon from "../../assets/info-circle.svg";
import CustomButton from "../../components/CommonComponents/custom-button/CustomButton";
import { constRoute } from "../../utils/Constants";

const OnlineCreditCard = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  return (
    <>
      <div className={style.headerContainer}>
        <ProtectedHeader />
      </div>

      <div className={style.pageContainer}>
        <div className={style.fluidContainer}>
          <Row justify={width <= 768 ? "center" : "left"}>
            {width <= 768 && (
              <img
                onClick={() => navigate("/")}
                className={style.arrowIcon}
                src={LeftArrow}
                width={20}
                alt=""
              />
            )}
            <h1>Online (Credit Card)</h1>
          </Row>
          <Row gutter={32} className={style.fluidRow}>
            <Col md={12} sm={24} xs={24}>
              <Row className={style.leftRow}>
                <img src={InfoIcon} alt="" />
                <p>
                  For Bidding: Lorem ipsum dolor sit amet consectetur adipiscing
                  elit facilisi, egestas laoreet id suscipit erat nibh tortor Eu
                  natoque interdum metus pretium turpis placerat. Leo For
                  Bidding: Lorem ipsum dolor sit amet consectetur adipiscing
                  elit facilisi, egestas laoreet id suscipit erat nibh tortor
                </p>
              </Row>
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Row className={style.rightRow}>
                <p>
                  By continuing you agree to our, you agree to our{" "}
                  <span onClick={() => navigate(constRoute?.termsAndConditions)} className={style.termsAndCondition} >Terms & Conditions</span>
                </p>
                <CustomButton title={"Pay $2300"} />
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default OnlineCreditCard;
