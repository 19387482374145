import axios from "axios";
import { CREDENTIALS, URLS } from "../../utils/Constants";

let headers = {
  language: localStorage.getItem("currentLanguage") || "en",
  country: localStorage.getItem('country')
};

const client = axios.create({
  baseURL: URLS.BASE_URL,
  headers,
});
client.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem(CREDENTIALS.AUTH_TOKEN);
    console.log({token})
    if (token) {
      config.headers.Authorization = "Bearer " + JSON.parse(token);
      config.headers.Accept = "application/json";
      config.headers.ContentType = "multipart/form-data";
    }
    return config;
  },
  (error) => {
    console.log({error})
    return Promise.reject(error);
  }
);
export default client;
