import client from "../config";

export const placeBidAPI = async (auctionId, bidPrice, isProxy) => {
  return await client.post(`/application/bids`, {
    auctionId,
    bidPrice,
    isProxy,
  });
};


export const getMyBidsAPI = async (price, bid, grade, date, ending) => {
  return await client.get(`/application/bids?page=1&price=${price}&bids=${bid}&grade=${grade}&date=${date}&isSoon=${ending}&status=&limit=1000`)
}

export const payToBidAPI = async (data) => {
  return await client.post(`/application/bid/pay`, data);
};