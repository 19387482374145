import { PLACE_BID_ACTION, SET_ALL_MY_BIDS, SET_ALL_MY_SINGLE_BID } from "../../actions/bids/BidsActions";

const initialState = {
  bids: [],
  myBids: [],
  singleBidData: {},
};

const BidsReducer = (state = initialState, action) => {
  switch (action.type) {
    case PLACE_BID_ACTION:
      return {
        ...state,
        bids: [action.payload, ...state.bids],
      };
    case SET_ALL_MY_BIDS:
      return {
        ...state,
        myBids: action.payload,
      };
    case SET_ALL_MY_SINGLE_BID:
      return {
        ...state,
        singleBidData: action.payload,
      };
    default:
      return state;
  }
};

export default BidsReducer;
