import moment from "moment";
import { EMPTY_NULL_DATA, statusConditionalColors } from "../const";
import { NotificationManager } from "react-notifications";
import { t } from "i18next";


export const addDebounce = (fn, delay) => {
  let timer;
  return (() => {
    clearTimeout(timer);
    timer = setTimeout(() => fn(), delay);
  })();
};

export const setRules = (title) => {
  return [
    {
      required: true,
      message: `${title} ${t("isRequired")}`,
    },
  ];
};

export const requestErrorHandel = async (error) => {
  console.log("error", error)
  const { status, data } = error?.response
  console.log("status", status, "data", data)
  if (error?.response?.status === 401) {
    NotificationManager.error(
      error?.response?.data?.message ?? "Internal Server Errror"
    );
    setTimeout(() => {
      localStorage.clear();
      // window.location.href = "/";
    }, 500);
    return;
  } else if (data?.statusCode === 422 || data?.statusCode === 403) {
    // if (error?.response?.data?.errors?.message?.length > 0) {
    //   errorMessage(
    //     error?.response?.data?.errors?.message ?? "Internal Server Errror"
    //   );
    // } else
    if (data?.statusCode === 422) {
      errorMessage(
        Object.values(error?.response?.data?.errors)[0]
      )
    } else {
      errorMessage(
        error?.response?.data?.message ?? "Internal Server Errror"
      );
    }
  } else if (!navigator.onLine) {
    NotificationManager.info(
      "No internet connection, Please check your internet"
    );
  } else {
    return errorMessage(
      error?.response?.data?.message ?? "Internal Server Errror"
    );
  }
};



export const successMessage = (message) => {
  return NotificationManager.success(message);
};

export const infoMessage = (info) => {
  return NotificationManager.info(info);
};


export const errorMessage = (info) => {
  return NotificationManager.error(info);
};



export const sortCol = (a, b, dataIndex) => {
  if (a[dataIndex]?.length > 0 && b[dataIndex]?.length > 0) {
    return a[dataIndex].length - b[dataIndex].length;
  } else {
    return null;
  }
};

export const renderItemDataOrEmptyNull = (text, fixedDigits) => {
  if (text) {
    if (typeof text === "number") {
      if (typeof fixedDigits === "number") {
        return numberWithCommas(text?.toFixed(fixedDigits));
      } else {
        return numberWithCommas(text);
      }
    } else {
      return text;
    }
  } else {
    if (text === 0) {
      return 0;
    } else {
      return EMPTY_NULL_DATA;
    }
  }
};

export function numberWithCommas(value) {
  if (
    value === "NaN" ||
    Number.isNaN(value) ||
    value === "" ||
    value === undefined
  ) {
    return "";
  } else {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

export const copyToClipboard = (text) => {
  var textField = document.createElement("textarea");
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
  infoMessage("Copied")
};


export const scrollToElement = (ref) => {
  if (ref.current) {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  }
};

export const findDataOnStatus = (status) => {
  switch (status) {
    case "B-0001": return "Open";
    case "B-0002": return "Won (Not Paid)";
    case "B-0003": return "Won (Paid)";
    case "B-0004": return "Lost (Out Bid)";
    default:
      return EMPTY_NULL_DATA
  }

  // B-0001 open auction and you are the highest bidder
  // B-0002 won the auction but payment pending
  // B-0003 won the auction and also Paid
  // B-0004 lost the auction and You are out bidder
  // B-0005 lost the auction and you have lost he bid


  // B-0001 highest
  // B-0002 won but not paid
  // B-0003 won and also paid
  // B-0004 out bid
  // B-0005 lost the bid
}

export const formatDate = (dateInput, format = "DD-MM-YYYY") => {
  const date = moment(dateInput); // Current date and time
  const formattedDate = date.format(format); // Format the date as YYYY-MM-DD
  return formattedDate
}

export const getTimeAMPMFromDate = (datePara) => {
  
  const date = new Date(datePara)

  // Get hours and minutes
  
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Determine whether it's AM or PM
  const amOrPm = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

  // Add leading zeros to minutes if necessary
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  // Create the final time string
  const formattedTime = `${formattedHours}:${formattedMinutes} ${amOrPm}`;
  return formattedTime;
};



export const getTimeFromOperationsWithDays = (firstTime, lastTime, Operation = "-") => {

  let timeOperated = "";
  const startTime = new Date(firstTime);
  const endTime = new Date(lastTime);

  switch (Operation) {
    case "+":
      timeOperated = startTime + endTime;
      break;
    case "-":
      timeOperated = startTime - endTime;

      break;
    case "*": {
      timeOperated = startTime * endTime;
      break;
    }
    case "/":
      timeOperated = startTime / endTime;
      break;
    default:
      timeOperated = new Date()
  }

  return convertMillisecondsToDigitalTime(timeOperated)
}

export const convertMillisecondsToDigitalTime = (milliseconds) => {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const days = Math.floor(totalSeconds / (24 * 60 * 60));
  const hours = Math.floor((totalSeconds % (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
  const seconds = totalSeconds % 60;

  return `${days} days ${hours}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
}

export const capitalizeString = (str) => {
  return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
}

export const goBack = (res, props) => {
  if (res?.data?.statusCode) {
    props.history.goBack();
  }
}

export const findGrade = (grade) => {
  switch (grade) {
    case "A":
      return 1;
    case "B":
      return 2;
    case "C":
      return 3;
    case "D":
      return 4;
    case "1":
      return "A";
    case "2":
      return "B";
    case "3":
      return "C";
    case "4":
      return "D";
    default:
      break;
  }
}

export const renderAuctionStatusColor = status => {
  if (status === '') {
    return null;
  }
  if (status === 'B-0001') {
    return statusConditionalColors.bidStautsBgColorLightBlue;
  } else if (status === 'B-0005' || status === 'B-0004') {
    return statusConditionalColors.bidStautsBgLightRed;
  } else if (status === 'B-0002') {
    return statusConditionalColors.bidStautsBgLightYellow;
  } else if (status === 'B-0003') {
    return statusConditionalColors.bidStautsBgColorLightGreen
  } else return '';
};


export const renderAuctionStatusBarColor = status => {
  if (status === 'B-0001') {
    return statusConditionalColors.bidStautsBgColorLightGreen;
  } else return statusConditionalColors.bidStautsBgMuddy;
};

