import { Form, message, Row } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { signupAPI } from "../../apis/auth/AuthAPIs";
import { fetchCountriesAPI } from "../../apis/static/StaticAPIs";
import AppButton from "../../components/Button/Button";
import CommonDropdown from "../../components/Dropdown/Dropdown";
import ImageInput from "../../components/ImageInput/ImageInput";
import CommonTextInput from "../../components/Input/Input";
import { constRoute } from "../../utils/Constants";
import { validatePhone } from "../../utils/helpers/GeneralHelpers";
import "./Signup.css";

const Signup = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [countries, setCountries] = useState([]);
  const [businessLicense, setBusinessLicense] = useState(undefined);
  const [photo, setPhoto] = useState(undefined);
  const [phoneNumber, setPhoneNumber] = useState("");

  const goToSignIn = () => navigate("/login");

  useEffect(() => {
    form.setFieldsValue({
      name: "",
      email: " ",
      countryId: null,
      phoneNo: "",
      businessAddress: "",
      password: "",

    })
  }, [])
  

  const onChangeNo = (value) => {
    form.setFieldValue("phoneNo", value);
    setPhoneNumber(value);
    validatePhone(value)
      ? form.setFields([
          {
            name: "phoneNo",
            errors: [""],
          },
        ])
      : form.setFields([
          {
            name: "phoneNo",
            errors: ["Please enter a valid phone number"],
          },
        ]);
  };

  const onSignup = async (values) => {
    if (!validatePhone(values.phoneNo)) {
      form.setFields([
        {
          name: "phoneNo",
          errors: ["Please enter a valid phone number"],
        },
      ]);
      return;
    }
    try {
      const data = {
        ...values,
        phoneNo: "+" + values?.phoneNo,
        countryId: Number(values.countryId),
        photo: photo ?? "",
        license: businessLicense ?? "",
      };
      setLoading(true);
      const result = await signupAPI(data);
      setLoading(false);
      if (result.data.statusCode === 200) {
        navigate("/verifyOTP", {
          state: {
            phoneNo: values.phoneNo,
            phoneToken: result.data.data.phoneToken,
          },
        });
      } else throw new Error(result.data.message);
    } catch (e) {
      setLoading(false);
      messageApi.error(
        e.response?.data?.message ??
          "An unknown error occured, please try again"
      );
    }
  };

  const fetchCountries = async () => {
    try {
      const result = await fetchCountriesAPI();
      if (result.data.statusCode === 200) {
        let countryList = result.data.data;
        countryList = countryList.map((country) => {
          return {
            value: country.id,
            label: country.name,
          };
        });
        setCountries(countryList);
      } else throw result;
    } catch (e) {
      console.log("errror in fetch country", e);
      setCountries([]);
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  return (
    <div className="signup-main">
      {contextHolder}
      <Form
        className="signup-form"
        form={form}
        layout={"vertical"}
        onFinish={onSignup}
        autoComplete={"newpassword"}
      >
        <h2 className="bold__font">{t(`signup.signup`)}</h2>
        <br />
        <Form.Item
          name={"name"}
          rules={[
            {
              required: true,
              message: "Please enter your full name",
            },
          ]}
        >
          <CommonTextInput placeholder={t(`signup.fullName`)} />
        </Form.Item>
        <Form.Item
          name={"email"}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                const regex =
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (regex.test(value.toLowerCase())) {
                  return Promise.resolve();
                } else
                  return Promise.reject(
                    new Error("Please enter a valid email address")
                  );
              },
            }),
          ]}
        >
          <CommonTextInput placeholder={t(`signup.email`)} />
        </Form.Item>
        <Form.Item
          name={"countryId"}
          rules={[
            {
              required: true,
              message: "Please select your country",
            },
          ]}
        >
          <CommonDropdown
            data={countries}
            placeholder={t("signup.selectCountry")}
            required
            showSearch
            filterOption={(input, option) =>
              (option?.label?.toLowerCase() ?? "").includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item
          name={"phoneNo"}
          rules={[
            {
              required: true,
              message: "Please enter your phone number",
            },
          ]}
        >
          <CommonTextInput
            type={"phone"}
            placeholder={t("signup.phoneNumber")}
            value={phoneNumber}
            setValue={onChangeNo}
            required
          />
        </Form.Item>
        <Form.Item name={"businessAddress"} rules={[{ required: true }]}>
          <CommonTextInput placeholder={t("signup.businessTitle")} required />
        </Form.Item>
        <Form.Item
          name={"password"}
          rules={[
            {
              required: true,
              min: 8,
              message:
                "Please enter a password with minimum 8 characters containing letters, numbers and symbols",
            },
          ]}
        >
          <CommonTextInput
            type={"password"}
            placeholder={t("signup.password")}
            required
          />
        </Form.Item>
        <ImageInput
          placeholder={t("signup.uploadBusinessLicense")}
          className={"image-input-width"}
          value={businessLicense}
          onSelect={setBusinessLicense}
          setUploading={setLoading}
          buttonText={t(`signup.upload`)}
        />
        <ImageInput
          placeholder={t("signup.uploadPhoto")}
          className={"image-input-width"}
          value={photo}
          onSelect={setPhoto}
          setUploading={setLoading}
          buttonText={t(`signup.upload`)}
        />
        <Row align={"top"}>
          <p>{t(`signup.byContinuing`)}</p>{" "}
          <p
            className={"create-account-btn bold__font t-red t-underline"}
            onClick={() => navigate(constRoute.termsAndConditions)}
          >
            {t(`signup.termsConditions`)}
          </p>
        </Row>
        <AppButton
          type="primary"
          className={"sign-in-btn"}
          htmlType={"submit"}
          loading={loading}
        >
          {t(`signup.signup`)}
        </AppButton>
        <Row align={"top"}>
          <p>{t(`signup.alreadyAccount`)}</p>{" "}
          <p
            className={"create-account-btn bold__font t-red"}
            onClick={goToSignIn}
          >
            {t(`login.sign-in`)}
          </p>
        </Row>
      </Form>
    </div>
  );
};

export default Signup;
