import React from "react";
import Sheet from "react-modal-sheet";

const BottomSheet = ({ open, ...props }) => {
  const reff = React.useRef();
  const snapTo = (i) => reff.current?.snapTo(i);

  return (
    <Sheet
      ref={reff}
      isOpen={open}
      onClose={() => snapTo(2)}
      snapPoints={[window.innerHeight - 50, 250, 80]}
      initialSnap={1}
      detent="full-height"
    >
      <Sheet.Container>
        <Sheet.Header
          style={{
            // padding: "5px 5px",
            // height: 110,
            width: "100%",
            // paddingTop: 10,
          }}
          //   onTap={() => setShowTemplate(false)}
        >
          {/* <div className="sheet_bar">
            <div className="sheet_bar_line" />
          </div> */}
          {props.header}
        </Sheet.Header>
        <Sheet.Content style={{ padding: "10px 20px", overflowY: "auto" }}>
          {props.children}
        </Sheet.Content>
      </Sheet.Container>
    </Sheet>
  );
};

export default BottomSheet;
