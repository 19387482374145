import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import { Col, Row } from "antd";
import AboutUsLogo from "../../assets/logo.png";
import LeftArrow from "../../assets/left-arrow.svg";
import useWindowSize from "../../utils/hooks/useWindowSize";
import { useNavigate } from "react-router-dom";
import ProtectedHeader from "../../components/ProtectedHeader/ProtectedHeader";
import { useTranslation } from "react-i18next";
import { getPagesApi } from "../../apis/pages";

const AboutUs = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [aboutUsData, setAboutUsData] = useState(null)

  const getUserProfileData = async () => {
    try {
      const result = await getPagesApi();
      if (result?.data?.statusCode === 200) {
        setAboutUsData( localStorage.getItem("i18nextLng") === "ch" ? result?.data?.data?.aboutUs.valueCh :  result?.data?.data?.aboutUs.valueEn)
      } else throw result;
    } catch (e) {
      console.log("errror in fetch", e);
    }
  };

  useEffect(() => {
    getUserProfileData()
  }, []);


  return (
    <>
      <div className={style.headerContainer}>
        <ProtectedHeader />
      </div>{" "}
      <div className={style.pageContainer}>
        <div className={style.fluidContainer}>
          <Row justify={width <= 768 ? "center" : "left"}>
            {width <= 768 && (
              <img
                onClick={() => navigate("/")}
                className={style.arrowIcon}
                src={LeftArrow}
                width={20}
                alt=""
              />
            )}
            <h1>{t("aboutUs.aboutUs")}</h1>
          </Row>
          <Row className={style.fluidRow} gutter={80}>
            <Col className={style.leftCol} md={14} sm={24} xs={24}>
              <div className={style.leftContainer}
                dangerouslySetInnerHTML={{ __html: aboutUsData }}
              >
               
              </div>
            </Col>
            <Col className={style.rightCol} md={10} sm={24} xs={24}>
              <img src={AboutUsLogo} alt="" />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
