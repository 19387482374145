import React, { useEffect, useState } from "react";
import { Col, Divider, Row } from "antd";
import JadeLogo from "../../assets/logo.png";
import "./Footer.css";

import WhatsAppIcon from "../../assets/whatsapp-icon.svg";
import WeChatIcon from "../../assets/wechat-icon.svg";
import TiktokIcon from "../../assets/tiktok-icon.svg";
import { constRoute } from "../../utils/Constants";
import Link from "antd/es/typography/Link";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { requestErrorHandel } from "../../utils/common-functions";
import { getCompanyAPI } from "../../apis/company";

const AppFooter = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [companyData, setCompanyData] = useState(null);


  const getCompany = async () => {
    setLoading(true);
    try {
      const response = await getCompanyAPI();
      if (response?.data?.statusCode === 200) {
        setCompanyData(response?.data?.data);
      }
    } catch (error) {
      requestErrorHandel(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCompany()
  }, [])

  return (
    <footer className="footer">
      <NavLink to={"/"}>
        <img
          src={JadeLogo}
          alt={"logo"}
          width={50}
          height={50}
        />
      </NavLink>
      <Row gutter={[30]}>
        <Col lg={10} xs={24}>
          <p>{t(`footer.appDescription`)}</p>
        </Col>
        <Col lg={8} xs={24}>
          <Row gutter={[10]}>
            <Col span={12}>
              <Link href={constRoute?.home}>
                <p>{t(`navMenu.home`)}</p>
              </Link>
            </Col>
            <Col span={12}>
              <Link href={constRoute?.aboutUs}>
                <p>{t(`navMenu.aboutUs`)}</p>
              </Link>
            </Col>
            <Col span={12}>
              <Link href={constRoute?.home}>
                <Link href={constRoute?.auctions}>
                  <p>{t(`navMenu.auctions`)}</p>
                </Link>
              </Link>
            </Col>
            <Col span={12}>
              <Link href={constRoute?.contactUs}>
                <p>{t(`navMenu.contact`)}</p>
              </Link>
            </Col>
          </Row>
        </Col>

        <Col lg={4} xs={24}>
          <p>{t(`footer.followUsAt`)}</p>
          <Row className="social-row">
            <Link target="_blank" href={companyData?.tikTok?.value}>
              <img src={TiktokIcon} alt={"tiktok"} />
            </Link>
            <Link target="_blank" href={companyData?.weChat?.value}>
              <img  src={WeChatIcon} alt={"WeChat"} />
            </Link>
            <Link target="_blank" href={`https://wa.me/${companyData?.whatsApp?.value}`}>
              <img src={WhatsAppIcon} alt={"whatsapp"} />
            </Link>
          </Row>
        </Col>
      </Row>

      <Row className="third-row" align={"middle"} justify={"space-between"}>
        <Col xs={24}>
          <Link href={constRoute?.termsAndConditions} className="medium__font">
            {t(`footer.termsConditions`)}
          </Link>
          <Divider type="vertical" style={{ backgroundColor: "black" }} />
          <Link href={constRoute?.privacyPolicy} className="medium__font">
            {t(`footer.privacyPolicy`)}
          </Link>
        </Col>
      </Row>
      <p style={{ fontSize: "13px", textAlign: "right" }}>
        {t(`footer.rightsReserved`)}
      </p>
    </footer>
  );
};

export default AppFooter;
