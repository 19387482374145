import { DatePicker, Input } from "antd";
import React from "react";
import "./Input.css";
import EyeClosedIcon from "../../assets/close-eye-icon.svg";
import EyeOpenedIcon from "../../assets/open-eye-icon.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";
import ar from "react-phone-input-2/lang/ar.json";
import { useMediaQuery } from "@uidotdev/usehooks";
import locale from "antd/es/date-picker/locale/zh_CN"; // Import the Chinese locale

const CommonTextInput = ({ isDisabledCode = false, type, ...props }) => {
  const { i18n } = useTranslation();
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const placeholder = `${props.placeholder} ${props.required ? "*" : ""}`;

  return type === "password" ? (
    <Input.Password
      iconRender={(visible) =>
        visible ? (
          <img src={EyeClosedIcon} alt={"hide password"} />
        ) : (
          <img src={EyeOpenedIcon} alt={"show password"} />
        )
      }
      {...props}
      placeholder={placeholder}
      className={`common-input ${props.className}`}
    />
  ) : type === "phone" ? (
    <div className={i18n.language === "ar" ? "phone-input-ar" : ""}>
      <PhoneInput
        localization={i18n.language === "ar" ? ar : undefined}
        inputStyle={{
          borderRadius: 10,
          height: "2.5rem",
          width: props.width ? props.width : isSmallDevice ? "80vw" : "25vw",
        }}
        buttonStyle={
          i18n.language === "ar"
            ? {
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
                paddingRight: 10,
              }
            : { borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }
        }
        defaultMask={".........."}
        alwaysDefaultMask={".........."}
        specialLabel="Phone Number"
        country={"ae"}
        onChange={(value) => props.setValue(value)}
        value={props.value}
        countryCodeEditable={false}
        enableSearch
        // isValid={(value, country) => {
        //   console.log(value);
        //   const countryCode = country.dialCode;
        //   const onlyNumber = value.split(countryCode)[1];
        //   console.log({ onlyNumber });
        //   if (onlyNumber.length > 0) {
        //     const regex = /^[0-9]{10,15}$/;
        //     // const regex =
        //     // /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{5}[-\s\.]?[0-9]{2,6}$/;
        //     return regex.test(value)
        //       ? true
        //       : "Please enter a valid phone number";
        //   } else return true;
        // }}
        disabled={props.disabled}
      />
    </div>
  ) : type === "number" ? (
    <Input
      {...props}
      className={`common-input ${props.className}`}
      placeholder={placeholder}
      type={type}
      autoComplete={"newpassword"}
    />
  ) : type === "date" ? (
    <DatePicker
      {...props}
      className={`common-input date-picker ${props.className}`}
      placeholder={placeholder}
      type={type}
      locale={
        props.locale ||
        (localStorage?.getItem("currentLanguage") === "ch" && locale)
      }
    />
  ) : (
    <Input
      {...props}
      className={`common-input ${props.className}`}
      placeholder={placeholder}
      autoComplete={"off"}
    ></Input>
  );
};

export default CommonTextInput;
