export const SET_WATCHLIST = "SET_WATCHLIST";
export const ADD_TO_WATCHLIST = "ADD_TO_WATCHLIST";
export const REMOVE_FROM_WATCHLIST = "REMOVE_FROM_WATCHLIST";

export const _setWatchlistAction = (data) => {
  return (dispatch) =>
    dispatch({
      type: SET_WATCHLIST,
      payload: data,
    });
};

export const _addToWatchListAction = (data) => {
  return (dispatch) =>
    dispatch({
      type: ADD_TO_WATCHLIST,
      payload: data,
    });
};

export const _removeFromWatchListAction = (data) => {
  return (dispatch) =>
    dispatch({
      type: REMOVE_FROM_WATCHLIST,
      payload: data,
    });
};
