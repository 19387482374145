// urls
export const URLS = {
  BASE_URL: "https://api.mkjades.com/", // live / staging
  // BASE_URL: "https://jade-api.pureelements.ae/", // dev 
  WEBSITE_DEV_URL: "https://jade-api.pureelements.ae",
  WEBSITE_STAGING_URL: "https://www.mkjades.com"
};

export const CREDENTIALS = {
  AUTH_TOKEN: "AUTH_TOKEN",
};
export const CHAT_WHATSAPP_NUMBER = +971503749645;

export const LANGUAGE = "currentLanguage"

export const JWT_AUTH_ACCESS_TOKEN = "token"

export const constRoute = {
  home: "/",
  signup: "/signup",
  login: "/login",
  forgotPasswordPhone: "/forgot-password-verify-phone",
  forgotPasswordOtp: "/forgot-password-otp",
  forgotPassword: "/forgot-password",
  allCategories: "/categories",
  allAuctions: "/all-auctions",
  auctions: '/auctions',
  notification: '/notification',
  myBids: '/my-bids',
  updatePassword: "/update-password",
  updateProfile: "/update-profile",
  verifyOTP: "/verifyOTP",
  aboutUs: "/about-us",
  contactUs: '/contact-us',
  termsAndConditions: "/terms-and-conditions",
  privacyPolicy: "/privacy-policy",
  watchlist: "/watchlist",
  onlineCreditCard: '/online-credit-card',
  payByAccount: '/pay-by-account',
  payByCheque: '/pay-by-Cheque',
  uploadReceipt: '/upload-receipt',
  deposit: '/deposit',
  addNewAddress: '/add-new-address',
  myDeliveryAddress: '/my-delivery-address',
  checkout: '/checkout',
}