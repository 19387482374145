import React, {useEffect, useState} from 'react';

const TimeRemainingTimer = ({endAt}) => {
  const [timeRemaining, setTimeRemaining] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = Date.now();
      const endsAtTime = new Date(endAt).getTime();
      const timeDifference = Math.max(0, endsAtTime - currentTime) / 1000 - 1; // Reduce by 1 second and convert to seconds

      if (timeDifference <= 0) {
        // The auction has ended, so clear the timer
        clearInterval(intervalId);
        setTimeRemaining('0d:0h:0m:0s');
        return;
      }

      const days = Math.floor(timeDifference / (3600 * 24));
      const hours = Math.floor((timeDifference % (3600 * 24)) / 3600);
      const minutes = Math.floor((timeDifference % 3600) / 60);
      const seconds = Math.floor(timeDifference % 60);

      let remainingTime = '';

      if (days > 0) {
        remainingTime = `${days}d:`;
      }

      remainingTime += `${hours}h:${minutes}m:${seconds}s`;
      setTimeRemaining(remainingTime);
    }, 1000);

    return () => {
      // Clear the interval when the component unmounts
      clearInterval(intervalId);
    };
  }, [endAt]);

  return timeRemaining;
};

export default TimeRemainingTimer;
