import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import { Col, Row, Slider } from "antd";
import LeftArrow from "../../assets/left-arrow.svg";
import useWindowSize from "../../utils/hooks/useWindowSize";
import { useLocation, useNavigate } from "react-router-dom";
import ProtectedHeader from "../../components/ProtectedHeader/ProtectedHeader";
import InfoIcon from "../../assets/info-circle.svg";
import CommonTextInput from "../../components/Input/Input";
import BankImage from "../../assets/bank.svg";
import { constRoute } from "../../utils/Constants";
import QRCodeImg from "../../assets/qr-code.jpg";
import { getCryptoApi, getWalletApi, postdepositApi } from "../../apis/deposit";
import {
  copyToClipboard,
  errorMessage,
  renderItemDataOrEmptyNull,
  requestErrorHandel,
} from "../../utils/common-functions";
import Loader from "../../components/Loader/Loader";
import { CopyOutlined } from "@ant-design/icons";
import CustomButton from "../../components/CommonComponents/custom-button/CustomButton";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { CURRENCY_SYMBOL } from "../../utils/const";
import NoDataFound from "../../components/CommonComponents/no-data-found";

const Deposit = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const location = useLocation();
  const date = location?.state?.date;
  const file = location?.state?.file;
  const filesDepositAmount = location?.state?.depositAmount;
  const [depositAmount, setDepositAmount] = useState(0);
  const [walletData, setWalletData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cryptoDetails, setCryptoDetails] = useState();

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getWalletApi();
      if (response?.data?.statusCode === 200) {
        setWalletData(response?.data?.data);
      }
    } catch (error) {
      requestErrorHandel(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCryptoData = async () => {
    setLoading(true);
    try {
      const response = await getCryptoApi();
      if (response?.data?.statusCode === 200) {
        setCryptoDetails(response?.data?.data);
      }
    } catch (error) {
      requestErrorHandel(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    fetchCryptoData();
  }, []);

  useEffect(() => {
    if (filesDepositAmount) {
      setDepositAmount(filesDepositAmount);
    }
  }, [filesDepositAmount]);

  const onDeposit = async () => {
    setLoading(true);
    try {
      const payload = {
        amount: depositAmount,
        date: moment(date).toISOString(),
        receipt: file,
      };
      const response = await postdepositApi(payload);
      if (response?.data?.statusCode === 200) {
        navigate(constRoute?.auctions);
      }
    } catch (error) {
      requestErrorHandel(error);
    } finally {
      setLoading(false);
    }
  };

  const onChangeLimit = (e) => {
    setDepositAmount(5000 * e);
  };

  return (
    <>
      <div className={style.headerContainer}>
        <ProtectedHeader />
      </div>
      <Loader loading={loading} />
      <div className={style.pageContainer}>
        <div className={style.fluidContainer}>
          <Row justify={width <= 768 ? "center" : "left"}>
            {width <= 768 && (
              <img
                onClick={() => navigate("/")}
                className={style.arrowIcon}
                src={LeftArrow}
                width={20}
                alt=""
              />
            )}
            <h1>{t(`deposit.deposit`)}</h1>
          </Row>
          <Row gutter={20} className={style.topRow}>
            <Col md={12} sm={24} xs={24}>
              <div className={style.leftColContainer}>
                <h3>{t(`deposit.chooseYourLimit`)}</h3>
                <Slider
                  defaultValue={1}
                  value={depositAmount / 5000}
                  onChange={onChangeLimit}
                />

                <Row justify={"space-between"}>
                  <p>
                    {CURRENCY_SYMBOL}
                    {renderItemDataOrEmptyNull(depositAmount, 2)}
                  </p>
                  <p>
                    {CURRENCY_SYMBOL}
                    {renderItemDataOrEmptyNull(500000, 2)}
                  </p>
                </Row>
                <Row justify={"center"}>
                  <span>{t("deposit.OR")}</span>
                </Row>
                <CommonTextInput
                  type={"number"}
                  placeholder={t("enterYourLimitHere")}
                  value={depositAmount}
                  onChange={(e) => {
                    if (e.target.value <= 500000) {
                      setDepositAmount(Number(e.target.value));
                    }
                  }}
                />
                <Row justify={"space-between"} className={style.totalPay}>
                  <h4>{t(`deposit.youShouldPay`)}</h4>
                  <h4>
                    {CURRENCY_SYMBOL}
                    {renderItemDataOrEmptyNull((depositAmount / 5), 2)}
                  </h4>
                </Row>
              </div>
              <br />
              <br />
              <Row
                onClick={() => {
                  if (depositAmount) {
                    navigate(constRoute.uploadReceipt, {
                      state: { depositAmount },
                    });
                  } else {
                    errorMessage(
                      t("yourDepositAmountShouldBeGreaterThan0")
                    );
                  }
                }}
                className={style.rightInfoRow}
              >
                <Row>
                  {file ? (
                    <img src={file} alt="" />
                  ) : (
                    <img src={BankImage} style={{ width: "50px" }} alt="bank" />
                  )}
                  <Row align={"middle"}>
                    <div>
                      <h4> {t(`deposit.uploadReceipt`)} </h4>
                      {date && (
                        <CommonTextInput
                          showTime
                          disabled
                          type="date"
                          value={moment(date)}
                          placeholder="Date"
                        />
                      )}
                    </div>
                  </Row>
                </Row>
                <p className={style.uploadText}>
                  {" "}
                  {file || date ? t("reUpload") : t(`deposit.upload`)}{" "}
                </p>
              </Row>
              <Row>
                <CustomButton
                  disabled={!depositAmount || !file}
                  onClick={onDeposit}
                  className={style.btn}
                  title={t(`deposit.deposit`)}
                />
              </Row>
            </Col>
            <Col md={12} sm={24} xs={24}>
              <div className={style.rightTopColContainer}>
                <Row justify={"space-between"}>
                  <p>{t(`deposit.currentDeposit`)}</p>
                  <p>
                    {CURRENCY_SYMBOL}
                    {renderItemDataOrEmptyNull(
                      Number(walletData?.currentBalance), 2
                    )}
                  </p>
                </Row>
                <Row justify={"space-between"}>
                  <p>{t(`deposit.totalLimit`)}</p>
                  <p>
                    {CURRENCY_SYMBOL}
                    {renderItemDataOrEmptyNull(
                      walletData?.totalBalance, 2
                    )}
                  </p>
                </Row>
                <Row justify={"space-between"}>
                  <p>{t(`deposit.availableLimit`)}</p>
                  <p>
                    {CURRENCY_SYMBOL}
                    {renderItemDataOrEmptyNull(
                      walletData?.availableBalance, 2
                    )}
                  </p>
                </Row>
              </div>

              <div className={style.rightColContainer}>
                <Row className={style.rightMainRow}>
                  <Col span={24}>
                    <Row className={style.rightInfoRowInfoText}>
                      <img src={InfoIcon} alt="" />
                      <p>{t(`deposit.makeDepositCrypto`)}</p>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <div className={style.rightColPayment}>
                      <h3>{t(`deposit.paymentViaCrypto`)}</h3>

                      {cryptoDetails?.name?.length > 0 ? (
                        <div>
                          <div className={style.QRCodeImage}>
                            {/* <Col span={12} > */}
                            <img src={cryptoDetails?.image} alt="qrCode" />
                            {/* </Col> */}
                          </div>

                          <Row className={style.rightInfoRow}>
                            <div>
                              <h4>{t(`deposit.walletDetails`)}</h4>
                              <p> {cryptoDetails?.name} {t("tronTRC20ID")}</p>
                              <h4>
                                {cryptoDetails?.value}
                                <CopyOutlined
                                  className={style.copyIcon}
                                  onClick={() =>
                                    copyToClipboard(cryptoDetails?.value)
                                  }
                                />
                              </h4>
                              <p>{t(`deposit.uploadReceiptScreenshot`)}</p>
                            </div>
                          </Row>
                        </div>
                      ) : (
                        <NoDataFound content={() => <p>{t(`deposit.cryptoDetailsNotFound`)}</p>} />)}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Deposit;
