import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import { Row } from "antd";
import LeftArrow from "../../assets/left-arrow.svg";
import useWindowSize from "../../utils/hooks/useWindowSize";
import { useNavigate } from "react-router-dom";
import ProtectedHeader from "../../components/ProtectedHeader/ProtectedHeader";
import { useTranslation } from "react-i18next";
import { getPagesApi } from "../../apis/pages";

const TermsAndCondition = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const { t } = useTranslation();
  const [termsAndConditionsData, setTermsAndConditionsData] = useState(null)

  const getUserProfileData = async () => {
    try {
      const result = await getPagesApi();
      if (result?.data?.statusCode === 200) {
        // setTermsAndConditionsData(result?.data?.data?.termsAndConditions)
        setTermsAndConditionsData( localStorage.getItem("i18nextLng") === "ch" ? result?.data?.data?.termsAndConditions.valueCh :  result?.data?.data?.termsAndConditions.valueEn)

      } else throw result;
    } catch (e) {
      console.log("errror in fetch", e);
    }
  };

  useEffect(() => {
    getUserProfileData()
  }, []);



  return (
    <>
      <div className={style.headerContainer}>
        <ProtectedHeader />
      </div>
      <div className={style.pageContainer}>
        <div className={style.fluidContainer}>
          <Row justify={width <= 768 ? "center" : "left"}>
            {width <= 768 && (
              <img
                onClick={() => navigate("/")}
                className={style.arrowIcon}
                src={LeftArrow}
                width={20}
                alt=""
              />
            )}
            <h1>{t(`footer.termsConditions`)}</h1>
          </Row>
          <Row className={style.fluidRow}>
            <div className={style.leftContainer}
              dangerouslySetInnerHTML={{__html: termsAndConditionsData}}
            >
              {/* <p>
                THIS DOCUMENT CONTAINS VERY IMPORTANT INFORMATION REGARDING YOUR
                RIGHTS AND OBLIGATIONS, AS WELL AS CONDITIONS, LIMITATIONS, AND
                EXCLUSIONS THAT MIGHT APPLY TO YOU. PLEASE READ IT CAREFULLY.
                THESE TERMS REQUIRE THE USE OF ARBITRATION TO RESOLVE DISPUTES,
                RATHER THAN JURY TRIALS. BY PLACING AN ORDER FOR PRODUCTS FROM
                THE SITE, YOU AFFIRM THAT YOU ARE OF LEGAL AGE TO ENTER INTO
                THIS AGREEMENT, AND YOU ACCEPT AND ARE BOUND BY THESE TERMS AND
                CONDITIONS. YOU AFFIRM THAT IF YOU PLACE AN ORDER ON BEHALF OF
                AN ORGANIZATION OR COMPANY, YOU HAVE THE LEGAL AUTHORITY TO BIND
                ANY SUCH ORGANIZATION OR COMPANY TO THESE TERMS. YOU MAY NOT
                ORDER OR OBTAIN PRODUCTS FROM THE SITE IF YOU (A) DO NOT AGREE
                TO THESE TERMS, (B) ARE NOT AT LEAST 18 YEARS OF AGE OR THE
                LEGAL AGE TO FORM A BINDING CONTRACT WITH THE COMPANY, OR (C)
                ARE PROHIBITED FROM ACCESSING OR USING THE SITE OR ANY OF THE
                SITE’S CONTENTS, PRODUCTS OR SERVICES BY APPLICABLE LAW.
              </p>
              <p>
                These terms and conditions for sales (these “Terms”) apply to
                the purchase and sale of products and services through
                <a href="www.jade.com"> www.jade.com </a>{" "}
                (collectively, the <b>“Website”</b>) and Jade App
                (Android and iOS) together with the Website, collectively, the
                <b>“Site”</b>). These Terms are subject to change by M3 Cars and
                More Inc., a Incorporate company (referred to as the{" "}
                <b>“Company”</b>, <b>“us”</b>, <b>“we”</b>, or <b>“our”</b> as
                the context may require) without prior written notice at any
                time, in our sole discretion. Any changes to these Terms will be
                in effect as of the “Last Updated Date” set forth below. You
                should review these Terms before purchasing any products that
                are available through the Site. Your continued use of the Site
                after the “Last Updated Date” will constitute your acceptance of
                and agreement to such changes. These Terms are an integral part
                of the Site’s Terms of Use that apply generally to the use of
                the Site. You should also carefully review our Privacy Policy
                before placing an order for products through the Site.
              </p>
              <h4>1. ORDER ACCEPTANCE AND CANCELLATION.</h4>
              <p>
                You agree that your order is an <b>offer</b> to buy, under these
                Terms, all products listed in your order. All orders must be
                accepted by us or we will not be obligated to sell the products
                to you. We may choose not to accept any orders in our sole
                discretion. After having received your order, we will send you a
                confirmation email with your order number and details of the
                items you have ordered. Acceptance of your order and the
                formation of the contract of sale between the Company and you
                will not take place unless and until you have received your
                order confirmation email. You may cancel your order, subject to
                the terms set forth herein, by calling our Customer Service
                Department.
              </p>
              <h4>2. PRICES AND PAYMENT TERMS.</h4>
              <p>
                a. All prices, posted on the Site are subject to change without
                notice. The list price charged for a product will be the price
                advertised on the Site at the time the order is placed. The
                price charged will be clearly stated in your order confirmation
                email. Price increases will only apply to orders placed after
                the time of the increase. Listed prices do not include taxes or
                charges for shipping and handling or insurance, if applicable.
                All applicable shipping and handling or insurance charges will
                be added to your total price, and will be itemized in your order
                confirmation email or final invoice. We strive to display
                accurate price information; however, we may on occasion make
                inadvertent typographical errors, inaccuracies, or omissions
                related to pricing and availability. We are not responsible for
                pricing, typographical, or other errors in any offer by us and
                we reserve the right to correct any such errors and to cancel
                any orders arising from such errors.
              </p>
              <p>
                b. All prices listed on the Site are exclusive of all sales,
                use, and excise taxes, and any other similar taxes, duties, and
                charges of any kind (including interest and penalties) imposed
                by any governmental authority on any amounts payable by you as a
                result of placing an order on the Site (collectively,{" "}
                <b>“Taxes”</b>), and you are responsible for all such Taxes. You
                represent and warrant that you are a reseller of any products
                purchased on the Site and that in connection with any orders
                placed on the Site, you: (i) are not obligated to pay Taxes; or
                (ii) have a valid resale or other exemption certificate (a
                <b> “Resale Certificate”</b>), as applicable. You agree to
                provide the relevant Resale Certificate or other documentation,
                if any, to the Company promptly upon request. The Company shall
                not be responsible for any Taxes if a Resale Certificate is not
                accepted by the applicable governmental authority, and you shall
                be solely liable for and obligated to pay for any Taxes assessed
                by a governmental authority or any other third party in
                connection with, arising out of, or otherwise resulting from the
                purchase of any products on the Site.
              </p>
              <p>
                c. Terms of payment are within our sole discretion and, unless
                otherwise agreed by us in writing, payment must be received by
                us before our acceptance of an order. All payments must be made
                from an account in your name by wire transfer or a Crypto
                Currency transfer.{" "}
              </p>
              <p>
                d. If you place an order for products, we will hold the products
                for a certain period of time (each, a <b>“Grace Period”</b>)
                following the date and time in which you placed the order (each,
                an <b>“Order Time”</b>) until the date and time specified by us
                in your order confirmation (each, a <b>“Cut-Off Time”</b>). If
                you do not make full payment for your order and your order is
                not reflected as paid within our payment processing system by
                the applicable Cut-Off Time, you cannot cancel the order after
                placing the bidding and wining the bid.
              </p>
              <h4>3. SHIPMENTS; DELIVERY; TITLE AND RISK OF LOSS.</h4>
              <p>
                a. We will arrange for shipment of the products to you. You will
                pay all shipping and handling charges unless otherwise specified
                in the order confirmation. You are responsible to correctly
                input your shipping address and freight option.
              </p>
              <p>
                b. Title and risk of loss pass to you upon our transfer of the
                products to the carrier. Shipping and delivery dates are
                estimates only and cannot be guaranteed. We are not liable for
                any delays in shipments or damage to shipments once placed with
                the carrier. You are responsible to select and pay for any
                shipment insurance.
              </p>
              <h4>
                6. MANUFACTURER’S WARRANTY AND DISCLAIMERS; LIMITATION OF
                LIABILITY.
              </h4>
              <p>
                a. We do not manufacture or control any of the products offered
                on the Site. The availability of products through the Site does
                not indicate an affiliation with or endorsement of any product,
                service, or manufacturer. Accordingly, we do not provide any
                warranties with respect to the products offered on the Site.
              </p>
              <p>
                b. ALL PRODUCTS OFFERED ON THE SITE ARE PROVIDED “AS IS” WITHOUT
                ANY WARRANTY WHATSOEVER, INCLUDING, WITHOUT LIMITATION, ANY: (I)
                WARRANTY OF MERCHANTABILITY; (II) WARRANTY OF FITNESS FOR A
                PARTICULAR PURPOSE; OR (III) WARRANTY AGAINST INFRINGEMENT OF
                INTELLECTUAL PROPERTY RIGHTS OF A THIRD PARTY; WHETHER EXPRESS
                OR IMPLIED BY LAW, COURSE OF DEALING, COURSE OF PERFORMANCE,
                USAGE OF TRADE, OR OTHERWISE.
              </p>
              <p>
                c. SOME JURISDICTIONS LIMIT OR DO NOT ALLOW THE DISCLAIMER OF
                IMPLIED OR OTHER WARRANTIES SO THE ABOVE DISCLAIMER MAY NOT
                APPLY TO YOU.
              </p>
              <p>
                d. YOU AFFIRM THAT WE SHALL NOT BE LIABLE, UNDER ANY
                CIRCUMSTANCES, FOR ANY BREACH OF WARRANTY CLAIMS OR FOR ANY
                DAMAGES ARISING OUT OF THE MANUFACTURER’S FAILURE TO HONOR ITS
                WARRANTY OBLIGATIONS TO YOU.
              </p>
              <p>
                e. <u>LIMITATION OF LIABILITY</u>. IN NO EVENT SHALL WE AND OUR
                REPRESENTATIVES BE LIABLE TO YOU OR ANY THIRD PARTY FOR
                CONSEQUENTIAL, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY,
                PUNITIVE, OR ENHANCED DAMAGES, LOST PROFITS OR REVENUES, OR
                DIMINUTION IN VALUE, ARISING OUT OF, OR RELATING TO, AND/OR IN
                CONNECTION WITH ANY BREACH OF THESE TERMS, REGARDLESS OF: (I)
                WHETHER SUCH DAMAGES WERE FORESEEABLE; (II) WHETHER OR NOT WE OR
                OUR REPRESENTATIVES WERE ADVISED OF THE POSSIBILITY OF SUCH
                DAMAGES; AND (III) THE LEGAL OR EQUITABLE THEORY (CONTRACT, TORT
                OR OTHERWISE) UPON WHICH THE CLAIM IS BASED. OUR AND OUR
                REPRESENTATIVES’ SOLE AND ENTIRE MAXIMUM LIABILITY, FOR ANY
                REASON, AND YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY CAUSE
                WHATSOEVER, SHALL BE LIMITED TO THE ACTUAL AMOUNT PAID BY YOU
                FOR THE PRODUCTS AND SERVICES IN QUESTION THAT YOU HAVE ORDERED
                THROUGH OUR SITE.
              </p>
              <h4>8. IMPORT AND EXPORT.</h4>
              <p>
                a. <u>For International Purchasers</u>. In the Company’s sole
                discretion, we may refuse to ship products to certain locations
                outside the United States in order to comply with United States
                laws and regulations. All purchasers located outside of the
                United States agree to comply with all applicable laws and
                regulations of the applicable destination country for any
                products purchased from the Site, including without limitation
                all applicable import laws and regulations.
              </p>
              <p>
                c. <u>For All Purchasers</u>. If you are a distributor or
                reseller (each, a <b> “Distributor/Reseller”</b>), you shall
                comply with all applicable export control and trade embargo
                laws, rules and regulations, including but not limited to the
                U.S. Export Administration Regulations, the U.S. International
                Traffic in Arms Regulations and the regulations as governed by
                the Office of Foreign Assets Control (<b>“OFAC”</b>), relating
                to the performance of your obligations hereunder, and shall not
                use, resell, export, re-export, distribute, transfer, dispose or
                otherwise deal with the products, or any spare parts, warranty
                items or technical data/technology related to the products,
                directly or indirectly, without first obtaining all necessary
                written consents, permits, and authorizations, and completing
                such formalities as may be required by any such laws, rules and
                regulations. Upon reasonable request, the Company shall provide
                Distributor/Reseller with assistance in determining the
                application of such laws, and in applying for necessary
                authorizations and completing required formalities. Failure by
                the Distributor/Reseller to comply with applicable
                export-control laws, rules and regulations shall constitute a
                material breach of the Agreement. Distributor/Reseller shall not
                do anything that would cause the Company to be in breach of the
                applicable export-control laws, rules and regulations, and shall
                protect, indemnify and hold the Company harmless from any claim,
                damages, liability costs, fees, and expenses incurred by the
                Company as a result of the failure or omission of
                Distributor/Reseller to comply with such laws, rules and
                regulations. You agree that all products purchased be shipped
                within all legal requirements. No products will be shipped to
                any{" "}
                <a href={constRoute.home}>
                  OFAC-sanctioned country or territory
                </a>{" "}
                or to any individual or entity specified as “Specially
                Designated Nationals” by OFAC.
              </p>
              <h4>9. PRIVACY</h4>
              <p>
                We respect your privacy and are committed to protecting it. Our
                <a href={constRoute?.privacyPolicy}> Privacy Policy</a> governs
                the processing of all personal information collected from you in
                connection with your purchase of products through the Site.
              </p>
              <h4>10. FORCE MAJEURE.</h4>
              <p>
                We will not be liable or responsible to you, nor be deemed to
                have defaulted or breached these Terms, for any failure or delay
                in our performance under these Terms when and to the extent such
                failure or delay is caused by or results from acts or
                circumstances beyond our reasonable control, including, without
                limitation, acts of God, flood, fire, earthquake, explosion,
                hurricane, governmental actions, war, invasion, or hostilities
                (whether war is declared or not), terrorist threats or acts,
                riot or other civil unrest, national emergency, revolution,
                insurrection, epidemic, pandemic, lockouts, strikes or other
                labor disputes (whether or not relating to our workforce), or
                restraints or delays affecting carriers or inability or delay in
                obtaining supplies of adequate or suitable materials, materials
                or telecommunication breakdown or power outage.
              </p>
              <h4>11. GOVERNING LAW.</h4>
              <p>
                All matters arising out of or relating to these Terms are
                governed by and construed in accordance with the internal laws
                of the State of Dallas without giving effect to any choice or
                conflict of law provision or rule (whether of the State of
                Dallas or any other jurisdiction) that would cause the
                application of the laws of any jurisdiction other than those of
                the State of Dallas.
              </p>
              <h4>12. WAIVER OF JURY TRIAL AND BINDING ARBITRATION.</h4>
              <p>
                a. YOU AND THE COMPANY ARE AGREEING TO GIVE UP ANY RIGHTS TO
                LITIGATE CLAIMS IN A COURT OR BEFORE A JURY, OR TO PARTICIPATE
                IN A CLASS ACTION OR REPRESENTATIVE ACTION WITH RESPECT TO A
                CLAIM. OTHER RIGHTS THAT YOU WOULD HAVE IF YOU WENT TO COURT MAY
                ALSO BE UNAVAILABLE OR MAY BE LIMITED IN ARBITRATION. ANY CLAIM,
                DISPUTE, OR CONTROVERSY (WHETHER IN CONTRACT, TORT, OR
                OTHERWISE, WHETHER PRE-EXISTING, PRESENT, OR FUTURE, AND
                INCLUDING STATUTORY, CONSUMER PROTECTION, COMMON LAW,
                INTENTIONAL TORT, INJUNCTIVE, AND EQUITABLE CLAIMS) BETWEEN YOU
                AND US ARISING FROM OR RELATING IN ANY WAY TO YOUR PURCHASE OF
                PRODUCTS THROUGH THE SITE, WILL BE RESOLVED EXCLUSIVELY AND
                FINALLY BY BINDING ARBITRATION.
              </p>
              <p>
                b. The arbitration will be administered by the American
                Arbitration Association (<b>“AAA”</b>) in accordance with its
                Commercial Arbitration Rules (the <b>“AAA Rules”</b>) then in
                effect, except if modified by this section (The AAA Rules are
                available at{" "}
                <a href="https://adr.org/consumer">https://adr.org/consumer</a>{" "}
                or by calling AAA at 1-800-778-7879. The arbitration shall be
                governed by New York law and shall occur at a venue to be agreed
                upon in writing by the parties hereto in New York County, New
                York. YOU ACKNOWLEDGE THAT YOU ARE GIVING UP YOUR RIGHTS TO
                LITIGATE CLAIMS IN A COURT OR BEFORE A JURY WITH RESPECT TO ANY
                SUCH CLAIM. The arbitrator will have exclusive authority to
                resolve any dispute relating to arbitrability and/or
                enforceability of this arbitration provision, including any
                unconscionability challenge or any other challenge that the
                arbitration provision or the Agreement is void, voidable, or
                otherwise invalid. The arbitrator will be empowered to grant
                whatever relief would be available in court under law or in
                equity. Any award of the arbitrator(s) will be final and binding
                on each of the parties and may be entered as a judgment in any
                court of competent jurisdiction. If you prevail on any claim
                that affords the prevailing party attorneys’ fees, the
                arbitrator may award reasonable fees to you under the standards
                for fee shifting provided by law.
              </p>
              <p>
                c. You may elect to pursue your claim in small-claims court
                rather than arbitration if you provide us with written notice of
                your intention do so within thirty (30) days of the date that
                you first accept these Terms. The arbitration or small-claims
                court proceeding will be limited solely to your individual
                dispute or controversy. You agree to arbitration on an
                individual basis. In any dispute, NEITHER YOU NOR THE COMPANY
                WILL BE ENTITLED TO JOIN OR CONSOLIDATE CLAIMS BY OR AGAINST
                OTHER CUSTOMERS IN COURT OR IN ARBITRATION OR OTHERWISE
                PARTICIPATE IN ANY CLAIM AS A CLASS REPRESENTATIVE, CLASS MEMBER
                OR IN A PRIVATE ATTORNEY GENERAL CAPACITY. The arbitral tribunal
                may not consolidate more than one person’s claims, and may not
                otherwise preside over any form of a representative or class
                proceeding. The arbitral tribunal has no power to consider the
                enforceability of this class arbitration waiver and any
                challenge to the class arbitration waiver may only be raised in
                a court of competent jurisdiction.
              </p>
              <p>
                d. If any provision of this arbitration agreement is found
                unenforceable, the unenforceable provision will be severed and
                the remaining arbitration terms will be enforced. You agree that
                with respect to any claim or controversy excluded from
                arbitration pursuant to the terms of this Section or determined
                by an arbitrator or court of competent jurisdiction to be
                excluded from this Section (<b>“Excluded Claim”</b>), you hereby
                irrevocably submit to the exclusive jurisdiction of the state
                and federal courts sitting in New York County, New York for the
                adjudication of any Excluded Claim, and hereby irrevocably
                waive, and agree not to assert in any suit, action or
                proceeding, any claim that you are not personally subject to the
                jurisdiction of any such court, that such suit, action or
                proceeding is brought in an inconvenient forum or that the venue
                of such suit, action or proceeding is improper. Nothing
                contained herein shall be deemed or operate to preclude a party
                from bringing suit or taking other legal action against the
                other party in any other jurisdiction to enforce a judgment or
                other court ruling in favor of such party. EACH PARTY HEREBY
                IRREVOCABLY WAIVES ANY RIGHT IT MAY HAVE TO, AND AGREES NOT TO
                REQUEST, A JURY TRIAL FOR THE ADJUDICATION OF ANY DISPUTE
                HEREUNDER OR IN CONNECTION WITH OR ARISING OUT OF THESE TERMS OR
                ANY TRANSACTION CONTEMPLATED HEREBY.
              </p>
              <h4>13. ASSIGNMENT</h4>
              <p>
                You will not assign any of your rights or delegate any of your
                obligations under these Terms without our prior written consent.
                Any purported assignment or delegation in violation of this
                Section 14 is null and void. No assignment or delegation
                relieves you of any of your obligations under these Terms.
              </p>
              <h4>14. NO WAIVERS.</h4>
              <p>
                The failure by us to enforce any right or provision of these
                Terms will not constitute a waiver of future enforcement of that
                right or provision. The waiver of any right or provision will be
                effective only if in writing and signed by a duly authorized
                representative of the Company.
              </p>
              <h4>15. NO THIRD PARTY BENEFICIARIES.</h4>
              <p>
                These Terms do not and are not intended to confer any rights or
                remedies upon any person other than you.
              </p>
              <h4>17. NOTICES</h4>
              <p>
                a. To You: We may provide any notice to you under these Terms
                by: (i) sending a direct message to the email address you
                provide; or (ii) by posting to the Site. Notices sent by email
                will be effective when we send the email and notices we provide
                by posting will be effective upon posting. It is your
                responsibility to keep your email address current.
              </p>
              <h4>18. SEVERABILITY</h4>
              <p>
                If any provision of these Terms is invalid, illegal, void, or
                unenforceable, then that provision will be deemed severed from
                these Terms and will not affect the validity or enforceability
                of the remaining provisions of these Terms.
              </p>
              <h4>19. ENTIRE AGREEMENT.</h4>
              <p>
                Our order confirmation, these Terms, the Terms of Use and the
                Privacy Policy will be deemed the final and integrated agreement
                between you and us on the matters contained in these Terms. In
                the event there is any conflict between the terms and conditions
                set forth in these Terms, Terms of Use, and the Privacy Policy,
                the terms and conditions most favorable to the Company shall
                control.
              </p>
              <p>
                <b>Policy ID:</b> MK-F001
              </p>
              <p>
                <b>Issue Date:</b> 06/01/2023
              </p> */}
            </div>
          </Row>
        </div>
      </div>
    </>
  );
};

export default TermsAndCondition;
