import { useCallback, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getAllAuctionsByCategoryAPI } from "../../apis/auctions/AuctionsAPI";
import HomeAuctionList from "../../components/HomeAuctionList/HomeAuctionList";
import Loader from "../../components/Loader/Loader";
import ProtectedHeader from "../../components/ProtectedHeader/ProtectedHeader";
import { _setAuctionsAction } from "../../redux/actions/home/HomeActions";

const AllAuctions = (props) => {
  const location = useLocation();
  console.log(location.state);
  const category = location.state?.category;
  const allAuctions = useSelector((state) => state.home.auctions);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchAuctions = useCallback(async () => {
    try {
      setLoading(true);
      const result = await getAllAuctionsByCategoryAPI(category.id);
      if (result.data?.statusCode === 200 && result.data.data) {
        setLoading(false);
        dispatch(_setAuctionsAction(result.data.data));
      }
    } catch (e) {
      setLoading(false);
      console.log("error in fetch auctions...", e);
    }
  }, [dispatch, category.id]);

  useEffect(() => {
    fetchAuctions();
  }, [fetchAuctions]);

  return (
    <div className="common-layout">
      <ProtectedHeader />
      <Loader loading={loading} />
      <section style={{ paddingTop: 20, paddingBottom: 20 }}>
        <HomeAuctionList data={allAuctions} title={category.title} />
      </section>
    </div>
  );
};

export default AllAuctions;
