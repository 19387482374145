import React from "react";
import style from "./style.module.scss";
import NotFoundIcon from '../../assets/not-found-icon.svg'
import ProtectedHeader from "../../components/ProtectedHeader/ProtectedHeader";

const NotFound = () => {
  return (
    <>
      <div className={style.headerContainer} >
        <ProtectedHeader />
      </div>
      <div className={style.pageContainer}>
        <div className={style.fluidContainer}>
          <img src={NotFoundIcon} alt="icons" />
          <h1>
            404 <span>error</span>{" "}
          </h1>
          <p>
            The page you are looking for cannot be found. Please contact our
            support team for assistance. Thank you for your understanding.
          </p>
        </div>
      </div>
    </>
  );
};

export default NotFound;
