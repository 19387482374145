export const SET_LOGIN = "SET_LOGIN";
export const SET_USER = "SET_USER";
export const UPDATE_PROFILE = "UPDATE_PROFILE"


export const _setLoginAction = (loggedIn, user) => {
    return dispatch => dispatch({
        type: SET_LOGIN,
        payload: {loggedIn, user}
    })
}

export const _updateProfileAction = (data) => {
    return dispatch => dispatch({
        type: UPDATE_PROFILE,
        payload: data
    })
}