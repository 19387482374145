import { useWindowSize } from "@uidotdev/usehooks";
import { useLocation, useNavigate } from "react-router-dom";
import ProtectedHeader from "../../components/ProtectedHeader/ProtectedHeader";
import style from "./style.module.scss";
import LeftArrow from "../../assets/left-arrow.svg";
import { Row } from "antd";
import { useTranslation } from "react-i18next";

const AuctionsTermsAndCondition = (props) => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const { state } = useLocation();
  const { t } = useTranslation();
  const data = state.data;
  return (
    <>
      <div className={style.headerContainer}>
        <ProtectedHeader />
      </div>
      <div className={style.pageContainer}>
        <div className={style.fluidContainer}>
          <Row justify={width <= 768 ? "center" : "left"}>
            {width <= 768 && (
              <img
                onClick={() => navigate("/")}
                className={style.arrowIcon}
                src={LeftArrow}
                width={20}
                alt=""
              />
            )}
            <h1>{t(`footer.termsConditions`)}</h1>
          </Row>
          <p>{data}</p>
        </div>
      </div>
    </>
  );
};
export default AuctionsTermsAndCondition;
