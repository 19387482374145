import { Form, message, Row } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { loginAPI } from "../../apis/auth/AuthAPIs";
import JadeLogo from "../../assets/logo.png";
import AppButton from "../../components/Button/Button";
import CommonTextInput from "../../components/Input/Input";
import { _setLoginAction } from "../../redux/actions/auth/AuthActions";
import { constRoute, CREDENTIALS } from "../../utils/Constants";
import "./Login.css";

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      email: "",
      password: "",
    })
  }, [])
  

  const goToSignup = () => navigate("/signup");

  const signIn = async (values) => {
    try {
      setLoading(true);
      const result = await loginAPI({
        email: values.email,
        password: values.password,
      });
      setLoading(false);
      if (result.data.statusCode === 200) {
        localStorage.setItem(
          CREDENTIALS.AUTH_TOKEN,
          JSON.stringify(result.data.data.authToken)
        );
        dispatch(_setLoginAction(true, result.data.data));
        navigate("/");
      } else throw result.data.data;
    } catch (e) {
      setLoading(false);
      console.log("error >>>>>>>>", e);
      messageApi.error(
        e.response?.data?.message ??
          "An unknown error ocurred, please login again with correct credentials"
      );
    }
  };

  const navigateForgotPassword = () => {
    navigate(constRoute.forgotPasswordPhone);
  };

  return (
    <div className="login-main">
      {contextHolder}
      <NavLink to={"/"}>
        <img width="40" src={JadeLogo} alt={"logo"} />
      </NavLink>
      <div className="welcome-text">
        <h3 className="bold__font">{t(`login.sign-in`)}</h3>
        <p>{t("login.welcome-back")}</p>
      </div>
      <Form form={form} onFinish={signIn} className={"login-form"}>
        <Form.Item
          name={"email"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <CommonTextInput placeholder={t(`login.email`)} className="input" />
        </Form.Item>
        <Form.Item
          name={"password"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <CommonTextInput
            placeholder={t(`login.password`)}
            className="input"
            type={"password"}
          />
        </Form.Item>

        <Row justify={"end"} align={"middle"} className="forgot-pwd-btn">
          <p onClick={navigateForgotPassword} className="bold__font t-red">
            {t(`login.forgot-password`)}
          </p>
        </Row>
        <AppButton
          type="primary"
          className={"sign-in-btn"}
          // onClick={signIn}
          htmlType={"submit"}
          loading={loading}
        >
          {t(`login.sign-in`)}
        </AppButton>
        <Row align={"top"}>
          <p>{t(`login.dont-have-an-account`)}</p>{" "}
          <p
            onClick={goToSignup}
            className={"create-account-btn bold__font t-red"}
          >
            {t(`login.create-account`)}
          </p>
        </Row>
      </Form>
    </div>
  );
};

export default Login;
