import { useMediaQuery } from "@uidotdev/usehooks";
import { Row } from "antd";
import { useTranslation } from "react-i18next";
import NotificationList from "../../components/NotificationsList/NotificationList";
import backIcon from "../../assets/back-arrow-icon.svg";
import "./MobileNotifications.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ProtectedHeader from "../../components/ProtectedHeader/ProtectedHeader";

const MobileNotifications = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const goBack = () => navigate(-1);

  return (
    <div className="common-layout mb-notifications-main">
      <section>
        <ProtectedHeader />
        <Row
          align={"middle"}
          className={"mb-notif-header"}
          justify={"space-between"}
        >
          {isSmallDevice && (
            <button onClick={goBack}>
              <img src={backIcon} alt={"back"} />
            </button>
          )}
          <h2 className="bold__font">{t(`navMenu.notifications`)}</h2>
          <div></div>
        </Row>
        <NotificationList isLoggedIn={isLoggedIn} />
      </section>
    </div>
  );
};
export default MobileNotifications;
