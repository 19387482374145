import { SET_AUCTIONS, SET_CATEGORIES } from "../../actions/home/HomeActions";

const initialState = {
    categories: [],
    auctions: [],
}


const HomeReducer  = (state = initialState, action) => {
    switch(action.type){
        case SET_CATEGORIES:
            return {
                ...state,
                categories: action.payload,
            }
            case SET_AUCTIONS:
                return {
                    ...state,
                    auctions: action.payload
                }
            default: 
            return state
    }
}

export default HomeReducer;