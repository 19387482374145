import { SET_ALL_AUCTIONS } from "../../actions/auctions/AuctionsActions";

const initialState = {
  auctionsData: [],
};

const AuctionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_AUCTIONS:
      return {
        ...state,
        auctionsData: action.payload,
      };
    default:
      return state;
  }
};

export default AuctionsReducer;
