import { Form, message } from "antd";
import React, { useState } from "react";
import AppButton from "../../components/Button/Button";
import CommonTextInput from "../../components/Input/Input";
import "./ForgotPassword.css";
import JadeLogo from "../../assets/logo.png";
import { forgotPasswordPhoneAPI } from "../../apis/auth/AuthAPIs";
import { useNavigate } from "react-router-dom";
import { constRoute } from "../../utils/Constants";
import { useTranslation } from "react-i18next";
import { validatePhone } from "../../utils/helpers/GeneralHelpers";

const ForgotPassowordPhone = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const [phoneNumber, setPhoneNumber] = useState("");

  const onChangeNo = (value) => {
    form.setFieldValue("phoneNo", value);
    setPhoneNumber(value);
    validatePhone(value)
      ? form.setFields([
          {
            name: "phoneNo",
            errors: [""],
          },
        ])
      : form.setFields([
          {
            name: "phoneNo",
            errors: ["Please enter a valid phone number"],
          },
        ]);
  };

  const onSubmit = async (values) => {
    if (!validatePhone(values.phoneNo)) {
      form.setFields([
        {
          name: "phoneNo",
          errors: ["Please enter a valid phone number"],
        },
      ]);
      return;
    }
    try {
      const result = await forgotPasswordPhoneAPI(values.phoneNo);
      if (result.data.statusCode === 200) {
        navigate(constRoute.forgotPasswordOtp, {
          state: { phoneNo: values.phoneNo },
        });
      } else throw result.data;
    } catch (e) {
      console.log("error", e);
      messageApi.error(
        e.response?.data?.message ??
          "An unknown error ocurred, please try again with correct credentials"
      );
    }
  };
  return (
    <div className="forgot-password-main">
      {contextHolder}

      <img width="40" src={JadeLogo} alt={"logo"} />
      <Form onFinish={onSubmit} form={form}>
        <h2>{t(`forgotPassword.forgotPassword`)}</h2>
        <p>{t(`forgotPassword.toResetPassword`)}</p>
        <Form.Item
          name={"phoneNo"}
          rules={[
            {
              required: true,
              message: "Please enter your phone number",
            },
            // ({ getFieldValue }) => ({
            //   validator(_, value) {
            //     const regex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

            //     if (regex.test(value)) {
            //       return Promise.resolve();
            //     } else
            //       return Promise.reject(
            //         new Error("Please enter a valid phone number")
            //       );
            //   },
            // }),
          ]}
        >
          <CommonTextInput
            type={"phone"}
            placeholder={t("forgotPassword.phoneNumber")}
            value={phoneNumber}
            setValue={onChangeNo}
            required
          />
        </Form.Item>
        <AppButton type="primary" htmlType={"submit"} loading={false}>
          {t(`forgotPassword.submit`)}
        </AppButton>
      </Form>
    </div>
  );
};

export default ForgotPassowordPhone;
